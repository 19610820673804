import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import common from './common/common'
import './api'
import axios from './modules/http'
//import set_req from './modules/reqs'
import ElementUI from 'element-ui'
// 引入bus总线
import bus from 'vue-bus'
// 使用bus总线
Vue.use(bus)
// Vue.prototype.$bus = new Vue()
//引入添加路由模块
import addRoutes from './modules/addRoutes.js'
//将添加路由模块挂载到原型
Vue.prototype.$addRouters = addRoutes

// 鉴权方法
import authentication from './common/authentication.js'
Vue.prototype.$authentication = authentication
// 时间戳不带时分秒的
import dates from './common/dates.js'
Vue.prototype.$dates = dates
// mdf
import mdk from './common/mdk.js'
Vue.prototype.$mdk = mdk
// 导入xlsx方法
import ImportXlsx from './common/ImportXlsx.js'
Vue.prototype.$ImportXlsx = ImportXlsx
// 
// import '../element-variables.scss'
import 'element-ui/lib/theme-chalk/index.css';
// fade/zoom 等
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component(CollapseTransition.name, CollapseTransition)
// 引入viewUI
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);
Vue.config.productionTip = false
// 使用
Vue.prototype.$common = common
Vue.use(ElementUI)
// Vue.prototype.$axios = axios;

import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);
import "babel-polyfill";

import '@/components/Components'
// 防抖处理-立即执行
const on = Vue.prototype.$on
Vue.prototype.$on = function (event, func) {
  let timer;
  let flag = true;
  let newFunc = func
  if (event == 'click' || event == 'keyCode') {
    newFunc = function () {
      if (flag) {
        func.apply(this, arguments)
        flag = false
      }
      clearTimeout(timer)
      timer = setTimeout(function () {
        flag = true
      }, 1000)
    }
  }
  on.call(this, event, newFunc)
}

import 'default-passive-events'
// 判断有没有token
router.beforeEach((to, from, next) => {
  if (to.path == '/login') return next()
  if (!localStorage.token) return next('/login')
  next()
})

// 引入Echarts
// import echarts from 'echarts'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

const components = require.context('./components/Components', true, /\.vue$/);
components.keys().forEach(key => {
  const name = key.match(/\/(\w+?)\.vue$/)[1];
  Vue.component(name, components(key).default);
});

import Tables from './components/Components/Tables/tables.vue'
Vue.component('Tables', Tables)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
Vue.prototype.$http = axios
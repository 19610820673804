<template>
	<div id="app">
		<el-container class="home-container">
			<!--头部-->
			<el-header>
				<div class="header-logo">
					<img src="../assets/logo.png" />
					<p class="logo-title">Let every family enjoy garden life</p>
				</div>
				<template>
					<div id="index-box" ref="index-box">
						<div class="marquee-index-box" ref="marquee" @mouseover="menter" @mouseleave="mleave">
							<p ref="cmdlist" id="pWidth">
								<svg
									style="margin-right: 5px; vertical-align: middle"
									t="1685582457798"
									class="icon"
									viewBox="0 0 1024 1024"
									version="1.1"
									xmlns="http://www.w3.org/2000/svg"
									p-id="2455"
									width="20"
									height="20"
								>
									<path
										d="M872.802928 755.99406 872.864326 755.99406 872.864326 755.624646Z"
										fill="#272536"
										p-id="2456"
									></path>
									<path
										d="M744.055658 192.799074c-4.814656-2.889817-9.601682-5.251607-15.442714-5.251607-14.262842 0-25.758664 11.559267-25.758664 25.805736 0 10.285251 6.088672 18.519796 14.6957 23.195282 94.679359 55.247278 158.344355 157.787676 158.344355 275.30416 0 117.424386-63.605643 219.931015-158.159136 275.18034-8.29492 4.538363-15.442714 13.050224-15.442714 23.583115 0 14.261818 11.559267 25.820062 25.791409 25.820062 5.716188 0 10.252505-2.202155 15.22475-5.063319 109.871363-64.133669 183.764304-183.143157 183.764304-319.520197C927.074995 375.785665 853.495186 257.010515 744.055658 192.799074z"
										fill="#272536"
										p-id="2457"
									></path>
									<path
										d="M773.946432 511.867994c0-79.96524-43.344181-149.739373-107.821681-187.289594-2.920516-1.52268-9.785877-4.520967-14.603603-4.520967-14.325263 0-25.914206 11.589966-25.914206 25.89988 0 9.616008 5.096065 18.176988 12.865006 22.666232 49.839105 28.307719 83.45983 81.829703 83.45983 143.244448 0 62.472843-34.801621 116.817566-86.070284 144.750755-7.457856 4.538363-12.397355 12.803607-12.397355 22.188348 0 14.325263 11.588943 25.943882 25.882484 25.943882 6.090718 0.031722 13.33061-3.542686 13.33061-3.542686C729.048873 664.171772 773.946432 593.294514 773.946432 511.867994z"
										fill="#272536"
										p-id="2458"
									></path>
									<path
										d="M541.3694 124.672464c-10.846022-5.219885-23.740704-3.790326-33.215496 3.712555-0.435928 0.358157-46.423309 36.914748-97.195669 74.296123-88.308255 65.081251-114.036219 75.925227-119.257128 77.649498l-110.6194 0c-0.63752 0-1.243317 0.062422-1.879813 0.093121l-56.504922 0c-14.231119 0-25.775037 11.543917-25.775037 25.775037l0 411.697573c0 14.261818 11.512195 25.761734 25.775037 25.761734l189.511191 0.027629c5.096065 1.865487 29.395494 13.0799 107.761306 76.999698 45.613874 37.162388 86.505189 73.485665 86.940095 73.829496 5.841032 5.218862 13.298887 7.92039 20.820188 7.92039 4.349051 0 8.729825-0.930185 12.862959-2.764973 11.277858-5.064342 18.517749-16.252149 18.517749-28.619828 0 0 0.031722-97.257068 0.031722-132.212184 0.808412-2.484587 1.213641-5.127787 1.213641-7.863085 0-2.792603-1.245364-578.026786-1.245364-578.026786C559.110459 140.892891 552.214399 129.924071 541.3694 124.672464zM508.308423 726.470653c0 1.494027-0.467651 94.617961-0.467651 94.617961-13.889335-11.745509-29.332049-24.64019-45.240367-37.507242-104.59008-84.702124-130.505309-91.816149-148.030451-91.816149-0.372483 0-0.683569 0.091074-1.025353 0.091074s-0.652869-0.091074-1.025353-0.091074L170.394297 691.765223c-18.037818 0-22.248723-5.128811-22.248723-23.246447L148.145573 352.559685c0-12.32163 1.461281-20.057825 16.298198-20.057825l128.065747 0c17.090237 0 43.315528-6.991228 157.787676-90.839915 20.383236-14.914688 40.330544-29.938869 57.544601-43.113937 0 0 0.373507 445.207781 0.467651 521.368368C507.779374 722.028481 508.308423 724.234729 508.308423 726.470653z"
										fill="#272536"
										p-id="2459"
									></path>
								</svg>
								最新公告： {{ news }}
							</p>
						</div>
					</div>
				</template>

				<div class="user">
					<div class="user-left">
						<p>welcome:{{ username }}</p>
						<div class="hov"></div>
					</div>
					<div class="user-right">
						<el-button type="info" @click="loginOut">登出</el-button>
					</div>
				</div>
				<el-button class="gonggao" @click="drawers()" type="info" v-if="pc">
					历<br />史<br />公 <br />告 <br />栏
				</el-button>
				<el-button class="applyBtn" @click="toPage()" type="info" v-if="userId <= 2">
					采<br />购<br />调<br />拨<br />待<br />审<br />核<br />
					<i
						@click.stop="applyPurchaseNum"
						class="el-icon-refresh refreshIcon"
						:class="$store.state.applyPurchaseNum.loading ? 'loading' : ''"
						title="刷新"
					></i>
					<sup v-if="$store.state.applyPurchaseNum.applyNum != 0">{{
						$store.state.applyPurchaseNum.applyNum > 99 ? '99+' : $store.state.applyPurchaseNum.applyNum
					}}</sup>
				</el-button>
			</el-header>
			<div class="nav-phone" v-if="phone">
				<div>purpleleaf</div>
				<input id="toggle-menu-phone" type="checkbox" />
				<label class="toggle-button" for="toggle-menu-phone" @click="menuclick()">
					<div class="toggle-buttons"></div>
				</label>
				<ul class="menu-phone" v-show="menusss">
					<el-menu
						class="phone-box"
						background-color="#313743"
						text-color="#fff"
						active-text-color="#409FFF"
						:unique-opened="true"
						:collapse="isCollapse"
						:collapse-transition="false"
						router
						:default-active="defaultActive"
					>
						<!-- 侧边栏 -->
						<template v-for="item in list">
							<el-menu-item :index="'/' + item.name_en" :key="item.name_en" v-if="!item.list">{{
								item.name
							}}</el-menu-item>
							<!-- 一级菜单-->
							<el-submenu :index="item.name_en + ''" :key="item.name_en" v-if="item.list">
								<!-- 一级菜单的模板区域 -->
								<template slot="title">
									<span>{{ item.name }}</span>
								</template>

								<!--二级菜单-->

								<el-menu-item
									style="z-index: 20"
									:index="`/${item.name_en}/${subMenu.name_en}`"
									v-for="subMenu in item.list"
									:key="subMenu.name_en"
								>
									<template slot="title">
										<!--<i class="el-icon-menu"></i> -->
										<span>{{ subMenu.name }}</span>
									</template>
								</el-menu-item>
							</el-submenu>
						</template>
					</el-menu>
				</ul>
			</div>

			<!--主体-->
			<el-container>
				<!-- 左侧菜单 -->
				<el-aside :width="isCollapse ? '64px' : '200px'">
					<!-- 折叠 -->
					<!-- <div class="toggle-button" @click="toggleCollapse">|||</div>  -->
					<el-menu
						background-color="#313743"
						text-color="#fff"
						active-text-color="#409FFF"
						:unique-opened="true"
						:collapse="isCollapse"
						:collapse-transition="false"
						router
						:default-active="defaultActive"
					>
						<!-- 侧边栏 -->
						<template v-for="item in list">
							<el-menu-item :index="'/' + item.name_en" :key="item.name_en" v-if="!item.list">{{
								item.name
							}}</el-menu-item>
							<!-- 一级菜单-->
							<el-submenu :index="item.name_en + ''" :key="item.name_en" v-if="item.list">
								<!-- 一级菜单的模板区域 -->
								<template slot="title">
									<span>{{ item.name }}</span>
								</template>

								<!--二级菜单-->

								<el-menu-item
									:index="`/${item.name_en}/${subMenu.name_en}`"
									v-for="subMenu in item.list"
									:key="subMenu.name_en"
								>
									<template slot="title">
										<!--<i class="el-icon-menu"></i> -->
										<span>{{ subMenu.name }}</span>
									</template>
								</el-menu-item>
							</el-submenu>
						</template>
					</el-menu>
				</el-aside>

				<el-drawer :visible.sync="drawer" :with-header="false">
					<div class="box-gg">
						<el-table :data="tableData1" stripe style="width: 100%">
							<el-table-column prop="message_detail" label="通知公告">
								<template slot-scope="scope">
									<span href="" class="buttonText">{{ scope.row.message_detail }}</span>
								</template>
							</el-table-column>
							<el-table-column prop="update_time" width="155" />
						</el-table>
					</div>
				</el-drawer>

				<el-main>
					<Tabel></Tabel>
					<div class="app-contain">
						<keep-alive exclude="limitsOfAuthority">
							<router-view></router-view>
						</keep-alive>
						<!-- <router-view ></router-view> -->
					</div>
					<!-- <keep-alive >
                        <router-view v-if="$route.meta.keepAlive"></router-view>
                    </keep-alive> -->
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>
<script>
// import Tabel from '@/components/Components/Tbs/Label';
import Tabel from '@/components/Components/Tbs/labelTwo';
import applyPurchaseNum from '@/store/modules/applyPurchaseNum';

export default {
	components: {
		Tabel
	},
	data() {
		return {
			pc: '', //电脑
			phone: '', //手机
			isCollapse: false,
			list: JSON.parse(localStorage.menu), //侧边栏
			username: localStorage.username,
			permission: localStorage.permission,
			value: 0,
			timer: '', //计时器
			pwidth: 0, //公告文本的宽度
			windowWidth: document.documentElement.clientWidth * 0.6, // 设备屏幕的宽度
			windowWidths: '', // 设备屏幕的宽度
			news: '',
			drawer: false,
			defaultActive: '/home_page',
			tableData1: [
				{
					message_detail: '',
					update_time: ''
				}
			],
			menusss: false,
			userId: localStorage.user_id,
			applyNum: ''
		};
	},
	beforeMount() {},

	created() {
		this.newsfunction();
		if (this.userId <= 2) {
			this.applyPurchaseNum();
		}
		window.setInterval(() => {
			setTimeout(() => {
				this.newsfunction();
			}, 0);
		}, 1200000);
		//侧边栏默认选中
		if (this.$route.path != this.defaultActive) {
			// console.log(this.$route);
			let path = this.$route.path;
			this.defaultActive = path;
		}

		this.ifwidth();
		// console.log(this.$route.path);
	},
	beforeRouteUpdate(to, from, next) {
		this.defaultActive = to.path;
		next();
	},
	mounted() {
		let element = this.$refs.cmdlist;
		this.pwidth = document.defaultView.getComputedStyle(element, '').width.split('px');
		this.timer = setInterval(this.clickCommend, 20);
	},
	watch: {
		value(newValue, oldValue) {
			let allWidth = parseInt(this.windowWidth) + parseInt(this.pwidth[0]);
			if (newValue <= -allWidth) {
				this.$refs.cmdlist.style.marginLeft = this.windowWidth + 'px';
				this.value = 0;
			}
		},
		$route: {
			handler: function (val, oldVal) {
				// console.log(val.path);
				// console.log(oldVal.path);
				if (val.path !== oldVal.path) {
					this.menusss = false;
				}
			},
			// 深度观察监听
			deep: true
		}
	},
	methods: {
		ifwidth() {
			if (window.screen.width <= 750) {
				this.pc = false;
				this.phone = true;
				this.rig = false;
			} else {
				this.pc = true;
				this.phone = false;
				this.rig = 'right';
			}
		},
		menuclick() {
			if (this.menusss == false) {
				this.menusss = true;
			} else if (this.menusss == true) {
				this.menusss = false;
			}
		},
		// 登出
		loginOut() {
			localStorage.removeItem('token');
			this.$router.replace('/login');
			localStorage.removeItem('username');
			localStorage.removeItem('menu');
			localStorage.removeItem('permission');
			localStorage.removeItem('user_id');
			localStorage.removeItem('level');
			localStorage.removeItem('pagenum');
			localStorage.removeItem('platform');
			localStorage.removeItem('country');
			localStorage.removeItem('shop');
			localStorage.removeItem('CabinetArrangement');
			this.$store.dispatch('tagsView/delAllViews'); // 退出登录清除顶部标签导航栏记录
			this.$store.dispatch('clearIntervalGetNum'); // 清除定时器
		},
		// 点击按钮切换左侧菜单的折叠与展开
		// toggleCollapse() {
		//   this.isCollapse = !this.isCollapse;
		// },
		clickCommend(e) {
			let _this = this;
			this.$nextTick(() => {
				this.value -= 1;
				this.$refs.cmdlist.style.marginLeft = _this.windowWidth + this.value + 'px';
			});
		},
		menter() {
			clearInterval(this.timer);
		},
		mleave() {
			this.timer = setInterval(this.clickCommend, 13.333);
		},
		newsfunction() {
			var loginData = {};
			loginData.version = '1.00';
			loginData.action = 'get_bulletin_board_new';
			loginData.user_token = localStorage.token;
			loginData.module = 'admin_manager';
			this.$api.login.login(loginData).then(res => {
				// console.log(res);
				if (res.data.status === 1) {
					res.data.result.created = dateFormat(res.data.result.created * 1000);
					res.data.result.update_time = dateFormat(res.data.result.update_time * 1000);
					this.news = res.data.result.message_detail;
				}
			});
		},
		applyPurchaseNum() {
			this.$store.dispatch('getApplyPurchaseNum');
			this.$store.dispatch('setIntervalGetNum');
		},
		drawers() {
			this.drawer = true;
			this.newswhole();
		},
		toPage() {
			this.$router
				.push({
					name: '采购调拨申请',
					path: 'applyPurchase',
					query: { status: '0' }
				})
				.then(() => {
					window.location.reload();
				});
		},
		// 公告栏
		newswhole() {
			var loginData = {};
			loginData.version = '1.00';
			loginData.action = 'get_bulletin_board_list_all';
			loginData.user_token = localStorage.token;
			loginData.module = 'admin_manager';
			this.$api.login.login(loginData).then(res => {
				// console.log(res);
				if (res.data.status === 1) {
					for (let i in res.data.result.result) {
						res.data.result.result[i].created = dateFormat(res.data.result.result[i].created * 1000);
						res.data.result.result[i].update_time = dateFormat(
							res.data.result.result[i].update_time * 1000
						);
						// res.data.result.result[i].message_detail = res.data.result.result[i].message_detail + '！'
					}
					this.tableData1 = res.data.result.result;
				}
			});
		}
	},
	beforeDestroy() {
		clearInterval(this.timer);
	}
};
</script>
<style lang="scss" scoped>
.home-container {
	height: 100%;
}

.el-container {
	height: 100vh;
	overflow: hidden;
}

.el-header {
	background-color: rgb(187 175 198 / 45%);
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 20px;
	overflow: hidden;
}

.logo-title {
	color: purple;
	font-family: 'emoji';
	margin-left: 30px;
}

.header-logo {
	width: 26%;
	display: flex;
	align-items: center;
}

.header-logo img {
	height: 45px;
	margin-left: 10px;
}

/* 左侧菜单 */
.el-aside {
	background-color: rgb(49, 55, 67);
	color: white;
	user-select: none;
	transition: width 0.3s;
}

.el-aside {
	overflow-x: hidden;
	min-height: calc(100vh - 60px);
	background-color: rgb(49, 55, 67);
	position: fixed;
	top: 60px;
	left: 0;
	bottom: 0;
	z-index: 999;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

.el-button--info {
	background-color: #b5a7e8 !important;
	border: none !important;
}

.iconfont {
	margin-right: 10px;
}

.el-menu {
	border-right: none;
}

// .toggle-button {
//   background-color: #4a5064;
//   font-size: 14px;
//   text-align: center;
//   line-height: 30px;
//   letter-spacing: 2px;
//   cursor: pointer;
// }

/* 右侧内容区 */
.el-main {
	background-color: rgb(233, 237, 241);
	position: absolute;
	top: 60px;
	left: 200px;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	padding: 0;
}
.app-contain {
	height: calc(100% - 34px);
	padding: 10px 20px;
}

.user {
	width: 15%;
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-end;

	.user-left {
		position: relative;
		margin-right: 5px;

		p {
			white-space: nowrap;
			width: 100%;
			color: purple;
			line-height: 60px;
			font-size: 14px;
			height: 60px;
		}
	}

	.user-right {
		line-height: 60px;
	}
}

#index-box {
	width: 60%;
	height: 50px;
	// margin-left: 50px;
	position: relative;
}

.marquee-index-box {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: auto;
	// background-color: #f8f8f8;
	overflow-x: hidden;
}

#pWidth {
	width: 1087px;
	height: 50px;
	padding: 0;
	margin: 0;
	line-height: 50px;
	display: block;
	word-break: keep-all;
	white-space: nowrap;
	overflow: hidden;
	font-family: 微软雅黑;
	font-size: 14px;
	color: purple;
	font-size: 16px;
	// background-color: purple;
}

::-webkit-scrollbar {
	width: 0 !important;
}

::-webkit-scrollbar {
	width: 0 !important;
	height: 0;
}

.gonggao {
	opacity: 0.7;
	position: fixed;
	bottom: 100px;
	right: 0px;
	z-index: 999;
	height: 100px;
	width: 40px;
	border-radius: 10px;
	padding: 0 0 0 0;
	box-shadow: 0 0 10px 0px #b5a7e8;
}

.applyBtn {
	opacity: 0.7;
	position: fixed;
	bottom: 220px;
	right: 0px;
	z-index: 999;
	width: 40px;
	border-radius: 10px;
	padding: 15px 0;
	box-shadow: 0 0 10px 0px #b5a7e8;
	.refreshIcon {
		margin-top: 5px;
		font-size: 18px;
		transition: all 0.3s;
	}
	.refreshIcon.loading {
		animation: rotate 2s linear infinite;
	}
	@keyframes rotate {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}
	.refreshIcon:hover {
		color: purple;
	}
	sup {
		background-color: #f56c6c;
		border-radius: 10px;
		color: #fff;
		display: inline-block;
		font-size: 12px;
		height: 18px;
		line-height: 18px;
		padding: 0 6px;
		text-align: center;
		white-space: nowrap;
		border: 1px solid #fff;
		position: absolute;
		top: -6px;
		left: -20%;
	}
}

.box-gg {
	span {
		color: purple;
	}

	.el-table__cell {
		-moz-column-width: 48px;
	}
}

// .el-main {
// animation: el-main 1s ease forwards;
// }
// @keyframes el-main {
// from {
// transform: translateX(-100%);
// }
// to {
// transform: translateX(0);
// }
// }
.nav-phone {
	display: none;
}

@media (max-width: 750px) {
	.el-header {
		font-size: 12px;

		.header-logo {
			p {
				display: none;
			}
		}

		// button {
		// 	width: 70px;
		// 	height: 40px;

		// 	span {
		// 		font-size: 12px;
		// 	}
		// }
		.applyBtn {
			width: 30px;
			bottom: 80px;
			font-size: 12px;
		}

		.marquee-index-box {
			width: 90%;
		}

		.user-left {
			p {
				display: none;
			}
		}
	}

	.el-aside {
		display: none;
	}

	.el-main {
		position: static;
	}
}

@media (max-width: 750px) {
	// 手机菜单
	.nav-phone {
		display: block;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		background-color: #00baf0;
		background: linear-gradient(to left, #f46b45, #eea849);
		/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
		color: #fff;
		height: 50px;
		padding: 1em;
	}

	.menu-phone {
		display: flex;
		flex-direction: row;
		list-style-type: none;
		margin: 0;
		padding: 0;
		z-index: 99;
	}

	// .menu-phone > li {
	//   margin: 0 1rem;
	//   overflow: hidden;
	// }

	// .menu-phone > li a {
	//   color: #ffffff;
	//   cursor:pointer;
	//   text-decoration:none;
	// }
	// .menu-phone > li a:hover {
	//   color: #ddc9c9;
	// }

	.toggle-button {
		display: none;
		height: 100%;
		width: 30px;
		cursor: pointer;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	#toggle-menu-phone {
		display: none;
	}

	.toggle-buttons,
	.toggle-buttons::before,
	.toggle-buttons::after {
		display: block;
		background-color: #fff;
		position: absolute;
		height: 4px;
		width: 30px;
		transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
		border-radius: 2px;
	}

	.toggle-buttons::before {
		content: '';
		margin-top: -8px;
	}

	.toggle-buttons::after {
		content: '';
		margin-top: 8px;
	}

	#toggle-menu-phone:checked + .toggle-button .toggle-buttons::before {
		margin-top: 0px;
		transform: rotate(405deg);
	}

	#toggle-menu-phone:checked + .toggle-button .toggle-buttons {
		background: rgba(255, 255, 255, 0);
	}

	#toggle-menu-phone:checked + .toggle-button .toggle-buttons::after {
		margin-top: 0px;
		transform: rotate(-405deg);
	}

	/* 
   自适应设计 ，把 3700 改为 700 看看？
   移动端菜单折叠，网页端为水平导航菜单。
*/

	.toggle-button {
		display: flex;
	}

	.menu-phone {
		position: absolute;
		top: 60px;
		margin-top: 50px;
		left: 0;
		flex-direction: column;
		width: 100%;
		justify-content: center;
		align-items: center;
	}

	// #toggle-menu-phone ~.menu-phone  {
	//   // height: 0;
	//   margin: 0;
	//   padding: 0;
	//   border: 0;
	//   transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
	// }

	// #toggle-menu-phone:checked.menu-phone li {
	//   border: 1px solid #333;
	//   height: 2.5em;
	//   padding: 0.5em;
	//   transition: height 400ms cubic-bezier(0.23, 1, 0.32, 1);
	// }

	.menu-phone > li {
		display: flex;
		justify-content: center;
		margin: 0;
		padding: 0.5em 0;
		width: 100%;
		color: white;
		background-color: #222;
	}

	.menu-phone > li:not(:last-child) {
		border-bottom: 1px solid #444;
	}

	.phone-box {
		width: 390px !important;
	}

	.gonggao {
		height: 90px !important;
		width: 20px !important;

		span {
			font-size: 12px;
		}
	}
}
</style>

<template>
    <div>
        <i class="el-icon-setting" style="font-size: 22px; cursor: pointer; margin-top: 3px" @click="visible = true"></i>
        <!-- 数据列显示与隐藏 -->
        <el-dialog title="显示/隐藏" :visible.sync="visible" width="65%">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>选择基本信息显示字段</span>
                </div>
                <div class="region-list-style">
                    <el-checkbox-group v-model="checkList">
                        <el-checkbox style="width: 90px; height: 30px; vertical-align: middle"
                            v-for="(item, index) in tableTitle" :key="index" :label="item.title"></el-checkbox>
                    </el-checkbox-group>
                </div>
            </el-card>
            <span slot="footer" class="dialog-footer">
                <el-button @click="visible = false">取 消</el-button>
                <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="checkedChange">保存</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "HideColumn",
    props: {
        PtableTitle: Array,
        data: Array
    },
    data() {
        return {
            // 显示控制列
            visible: false,
            // 保存默认勾选的列
            origin: [],
            // // 默认勾选的列
            checkList: [],
            tableTitle: [{title:'1',}]
        };
    },
    created() {
        if (this.data) {
            this.checkList = this.data
            this.origin = this.checkList;
        }
        if (this.PtableTitle) {
            this.tableTitle = this.PtableTitle
        }
    },
    methods: {
        // 重置为默认展示的列
        reset() {
            this.checkList = this.origin;
        },
        // 列的展示与隐藏
        checkedChange() {
            console.log(111);
            console.log(this.origin);
            console.log(this.checkList);
            console.log(this.tableTitle);
            // 首先重置为不展示
            this.tableTitle.forEach((item) => {
                item.open = false;
            });

            for (let i = 0; i < this.checkList.length; i++) {
                this.tableTitle.forEach((item) => {
                    if (item.title == this.checkList[i]) {
                        item.open = true;
                    }
                });
            }
            this.visible = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.cursor {
    cursor: pointer;
}

.cursor:hover {
    color: #f40;
}

.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}

.clearfix:after {
    clear: both;
}

.box-card {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    height: 90%;
}
</style>
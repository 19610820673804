import Vue from  'vue'

import Search from './Search'

const components = [Search]

const install = Vue  =>{
    components.forEach(component =>{
        if(!component.name) console.log('组件缺少name');
        Vue.component(component.name,component)
    })
}

install(Vue)
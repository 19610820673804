<template>
    <div class="input-container el-input">
        <el-input v-model="searchValue" :placeholder="hint" class="input-with-select" @keyup.native.enter="search">
            <el-button slot="append" icon="el-icon-search" @click="search" />
        </el-input>
    </div>
</template>

<script>
export default {
    name: "Search",
    data() {
        return {
            searchValue: ""
        };
    },
    props: {
        value: {
            type: [Number, String],
            required: true
        },
        hint: {
            type: String,
            default: "请输入"
        }
    },
    watch: {
        value: {
            immediate: true,
            handler(val) {
                this.searchValue = val;
            }
        },
        searchValue(val) {
            this.$emit("input", val);
        }
    },
    methods: {
        search() {
            console.log("search", this.value);
            this.$emit("search", this.searchValue);
        }
    }
};
</script>

<style lang="scss">
.input-container {
    display: inline-block;

}
</style>
import md5 from "md5";//在使用的页面引入加密插件


var m_k = 'jhjkhJAIH4GRKJSAD455';
export default {
    set_req(obj) {

        var key_list = [];
        for (var i in obj) {
            if (i != 'module' && i != 'action') {
                key_list.push(i);
            }
        }
        key_list.sort();
        // console.log(223);console.log(key_list);console.log(113);
        var mdf_str = '';
        for (var b in key_list) {
            mdf_str += obj[key_list[b]];
        }
        mdf_str += m_k;
        var res_str = md5(mdf_str);
        // 	console.log(res);
        return res_str;
    },
    // 下载文件公共方法
    downloadFile(paramObj) {
        let location_strs = `//pretest.ziyehuwai.com/api?`;
        for(let i in paramObj) {
            location_strs = location_strs + `${i}=${paramObj[i]}&`
        }
        location_strs = location_strs.slice(0, -1);
        window.location = location_strs;
    }

}

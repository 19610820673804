<template>
    <div class="box">
      <div class="tit">
        <h2>{{111 }}</h2>
      </div>
      <!-- 搜索框 -->
      <div class="add">
        <div class="inp">
        
        </div>
  
        <el-row>
          <el-button type="primary" @click="add_api(true)">添加接口文档</el-button>
          <el-dialog title="添加接口文档" :visible.sync="dialogFormVisible" top="10vh">
            <el-form :model="form">
              <el-form-item label="所属模块" :label-width="formLabelWidth">
                <el-input placeholder="请输入所属模块" v-model="form.module_name" autocomplete="off"></el-input>
              </el-form-item>
             
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="addlist">提 交</el-button>
            </div>
          </el-dialog>
        </el-row>
      </div>
  
      <div class="title">
        <el-table :v-if="tableData" :data="tableData" border style="width: 100%" v-for="(item,index) in tableData" :key="index">

            <el-table-column :prop="item.id" :label="item.a" width="60"> </el-table-column>


          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button @click="edit(scope.row.id)" type="primary" size="mini" icon="el-icon-edit">编辑</el-button>
              <el-button @click="del(scope.row.id)" type="danger" size="mini" icon="el-icon-delete"
                style="margin-left: 10px">删除</el-button>
              <el-dialog title="编辑接口文档" :append-to-body="true" :visible.sync="editBox" top="10vh">
                <el-form :model="form1">
                  <el-form-item label="所属模块" :label-width="formLabelWidth">
                    <el-input v-model="form1.module_name" autocomplete="off"></el-input>
                  </el-form-item>
                
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="editBox = false">取 消</el-button>
                  <el-button type="primary" @click="editboxs">确 定</el-button>
                </div>
              </el-dialog>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>
  </template>
  
  
  <script>
  export default {
    props: {
    name: {
      // type: String, // 限制类型
      required: true, // 限制必要性
      default: "", // 指定默认值
    },
  },
    data() {
      return {
        tableData:[],
      
      };
    },
        props: {

    },
    watch: {
        
    },
    created() {

      
    },
    methods: {
 
    },
    mounted() { },
  };
  </script>
  <style lang="scss" scoped>
  
  </style>
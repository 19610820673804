<template>
    <div class="tabel">
        <transition-group name="fade" class="tran-group">
            <div class="tabel-item" :class="{ active: item.name == $route.name }" v-for="(item, index) in history"
                @click="$route.path == item.path ? '' : $router.push({ path: item.path })" :key="item.name">
                <span>{{ item.name }}</span>
                <i class="el-icon-circle-close" v-if="index != 0" @click.stop="close(index)"></i>
            </div>
        </transition-group>
    </div>
</template>
<script>
export default {
    data () {
        return {
            history: [
                {
                    path: '/',
                    name: '首页'
                }
            ]
        };
    },
    created () {
        // console.log(this.$route);
        if (this.$route.name == '首页') {
            return this.history;
        } else {
            this.history.push(this.$route);
        }
    },
    methods: {
        addClass (event) {
            // console.log(event);
        },
        close (index) {
            if (this.history[index].path != this.$route.path) return this.history.splice(index, 1);
            const prePath = this.history[index - 1].path;
            this.$router.push({ path: prePath });
            this.history.splice(index, 1);
        }
    },
    watch: {
        $route: function (newVal, oldVal) {
            // console.log(newVal);
            const res = this.history.find(v => v.name == newVal.name);
            if (!res) {
                this.history.push(newVal);
            }
        }
    }
};
</script>
<style></style>
<style lang="scss" scoped>
// .fade-move {
//     transition: all .3s;
// }

.icons-enter-active {
    transition: all 0.3s;
}

.tran-group {
    display: flex;
    width: 100%;
}

.fade-leave-active {
    position: absolute;
    transition: all 0.5s;
}

.fade-enter-active {
    position: relative;
}

.tabel {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    box-shadow: 1px 1px 1px #666;
    display: flex;

    .tabel-item {
        display: flex;
        // justify-content: flex-end;
        min-width: 30px;
        max-width: 200px;
        width: 120px;
        text-align: center;
        background-color: #b5a7e8;
        user-select: none;
        margin: 0 5px;
        padding: 0 16px;
        line-height: 40px;
        align-items: center;
        transition: all 0.5s;
        // cursor: default;
        cursor: pointer;
        border-radius: 10px 10px 0 0;

        span {
            text-align: center;
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &:hover .el-icon-circle-close {
            width: 16px;
        }

        .el-icon-circle-close {
            transition: all 0.3s;
            overflow: hidden;
            width: 0;
            float: right;
            top: 0;
            line-height: 40px;
            font-size: 16px;
            cursor: pointer;

            &:hover {
                color: blueviolet;
            }
        }
    }

    .active {
        background-color: white;
    }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    redirect: '/home_page',
    meta: { keepAlive: true, affix: true },
  },
  {
    path: '/login',
    component: () => import('../views/login')
  },
]

const router = new VueRouter({
  routes
})
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
return routerPush.call(this, location).catch(error=> error)
}
router.beforeEach((to, from, next) => {
  if (to.path === '/home_page' && !localStorage.token) {
    next('/login')
  }
  next()
})
export default router

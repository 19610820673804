import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default new Vuex.Store({
  state: {
    loginData:{
        module: '',
        action: '',
        version: '',
        user_name: '',
        password: '',
        unixtime: '',
        userphoto: '',
        user_token:'',
        page:'',
    },
    routeHistory:[]
  },
  getters: {
  },
  mutations: {
    login(state,data){
        state.loginData = data
    },
    userphoto(state,v) {
      state.userphoto = v
    },
    upDateHistory(state,route){
      state.routeHistory.push(route)
    }
  },
  actions: {
  },
  modules: modules
})
<template>
    <div class="wayfair">
        <div class="topSearch">
            <el-date-picker v-model="dateSelect" type="daterange" align="right" unlink-panels range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" :clearable="false" :picker-options="pickerOptions">
            </el-date-picker>
            <el-select v-model="dimension" placeholder="请选择时间维度">
                <el-option label="日" value="day"></el-option>
                <el-option label="周" value="week"></el-option>
                <el-option label="月" value="month"></el-option>
            </el-select>
            <el-select v-model="group_type" placeholder="请选择汇总维度" clearable>
                <el-option v-if="type !== 'dataAll'" label="广告组id" value="ad"></el-option>
                <el-option label="sku" value="sku"></el-option>
                <el-option v-if="type == 'dataThird'" label="关键词" value="keyword"></el-option>
            </el-select>
            <el-input placeholder="请输入wayfair平台SKU" v-model.trim="sku" clearable />
            <el-input placeholder="请输入广告组ID" v-model.trim="campaign_id" clearable />
            <el-select v-model="classify_name" filterable placeholder="请输入三级分类" clearable>
                <el-option v-for="item in classificationList" :key="item.value" :label="item.classify"
                    :value="item.classify">
                </el-option>
            </el-select>
            <!-- <el-input placeholder="请输入三级分类" v-model.trim="classify_name" clearable /> -->
            <el-button type="primary" plain icon="el-icon-search" @click="serchlist">搜索</el-button>
            <el-upload v-if="type != 'dataAll'" class="upload" action="" :show-file-list="false" multiple
                :file-list="fileList" :http-request="clickUpload">
                <el-button type="success" plain class="el-icon-upload downloads-button"> 导入 </el-button>
            </el-upload>
            <el-button type="success" plain class="el-icon-download downloads-button" @click="downloadTable">
                导出
            </el-button>
        </div>
        <el-row :gutter="20">
            <div class="tab">
                <div class="tab-s">
                    <div class="tab1" v-for="(item, index) in tabArr" @click="tab(item.type, $event)" :key="index"
                        :class="{ active: isActive == index }">
                        <span>{{ item.title }}</span>
                    </div>
                    <!-- <div class="tab1" v-for="(item, index) in tabArr" @click="tab(item.label)"><p></p></div> -->
                </div>
            </div>
            <el-col :span="24">
                <div class="grid-content bg-purple">
                    <div id="chart-container"></div>
                </div>
            </el-col>
        </el-row>
        <div class="tableBox">
            <el-table v-loading="tableLoading" :data="tableData.list" ref="table" style="width: 100%" border
                height="700" :show-summary="true" :summary-method="handleSummary" @sort-change="sortChange">
                <el-table-column v-for="(columnItem, columnIndex) in tableColumn" :key="columnIndex"
                    :min-width="handleWidth(columnItem)" :prop="columnItem.prop" :label="columnItem.label"
                    :sortable="columnItem.sortable === true ? 'custom' : false" :sort-method="(a, b) => {
                    return a[columnItem.prop] - b[columnItem.prop];
                }
                ">
                    <template slot-scope="scope">
                        <div class="inDiv" v-if="columnItem.dataType && columnItem.dataType == 'array'">
                            <div>{{ scope.row[columnItem.prop][0] }}</div>
                            <!-- <div v-for="(item, index) in scope.row[columnItem.prop]" :key="index">{{ item }}</div> -->
                        </div>
                        <div class="inDiv" v-else>{{ scope.row[columnItem.prop] }}</div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pageInfo.pagenum" :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 500]"
                :page-size="pageInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="pageInfo.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 页面类型区分（汇总、1.0，3.0,7.0）
        type: {
            type: String,
            default: ''
        },
        // 列表数据
        tableData: {
            type: Object,
            default: {}
        },
        // 图表数据
        echartData: {
            type: Object,
            default: {}
        },
        // 列表表头配置
        tableColumn: {
            type: Array,
            default: []
        },
        // 页码信息
        queryInfo: {
            type: Object,
            default: {}
        },
        // 表格加载状态
        tableLoading: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            fileList: [],
            classificationList: [], // 三级分类列表
            dateSelect: [
                // new Date(new Date(today.getFullYear(), today.getMonth(), 1).toLocaleDateString()),
                // new Date(new Date().toLocaleDateString())
            ], // 日期选择
            dimension: 'day',
            group_type: '',
            sku: '',
            campaign_id: '',
            classify_name: '',
            tabArr: [
                {
                    title: '花费',
                    type: '0'
                },
                {
                    title: '整体展现',
                    type: '1'
                },
                {
                    title: '点击数',
                    type: '2'
                },
                {
                    title: '点击率',
                    type: '3'
                }
            ],
            pickerOptions: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick (picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近一个月',
                        onClick (picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近三个月',
                        onClick (picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ],
                disabledDate: this.disabledDueDate
            },
            echart: null,
            option: {
                // title: {
                //     text: 'Stacked Line'
                // },
                tooltip: {
                    trigger: 'axis'
                }, //提示框
                legend: {
                    data: ['今年', '去年']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                }, //控制图表位置
                toolbox: {
                    feature: {
                        saveAsImage: {} //下载
                    }
                },
                xAxis: {
                    type: 'category', //坐标轴类型
                    boundaryGap: false, //坐标轴两边留白策略
                    // data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月']
                    data: []
                },
                yAxis: {
                    type: 'value'
                    // type: 'category',
                    // data: [10, 20, 30, 40, 50, 60, 70,100]
                },
                series: [
                    {
                        name: '今年',
                        type: 'line',
                        data: [],
                        smooth: true,
                        areaStyle: {
                            opacity: 0.2
                        }
                    },
                    {
                        name: '去年',
                        type: 'line',
                        data: [],
                        smooth: true,
                        areaStyle: {
                            opacity: 0.2
                        }
                    }
                ]
            },
            pageInfo: this.queryInfo,
            sortQuery: {
                prop: '',
                order: ''
            },
            isActive: 0,
            tabdate: '0'
        };
    },
    created () {
        let today = new Date();
        // let firstDay = new Date(today.getFullYear(), today.getMonth(), 1).toLocaleDateString();
        // console.log('firstDay', firstDay);
        let t1 = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        let t2 = new Date(new Date().toLocaleDateString());
        this.dateSelect.push(t1, t2);
        this.serchlist();
        this.getClassifyList();
    },
    mounted () {
        this.initEchart();
    },
    updated () {
        this.$nextTick(() => {
            this.$refs.table.doLayout();
        });
    },
    watch: {
        echartData: {
            handler (data) {
                for (var i in data.list) {
                    if (this.tabdate == 0) {
                        this.option.series[0].data.push(data.list[i].spend);
                        this.option.xAxis.data.push(data.list[i].t);
                    }
                    if (this.tabdate == 1) {
                        this.option.series[0].data.push(data.list[i].impressions);
                        this.option.xAxis.data.push(data.list[i].t);
                    }
                    if (this.tabdate == 2) {
                        this.option.series[0].data.push(data.list[i].click);
                        this.option.xAxis.data.push(data.list[i].t);
                    }
                    if (this.tabdate == 3) {
                        this.option.series[0].data.push(data.list[i].click_rate);
                        this.option.xAxis.data.push(data.list[i].t);
                    }
                }
                for (var i in data.old_list) {
                    if (this.tabdate == 0) {
                        this.option.series[1].data.push(data.old_list[i].spend);
                    }
                    if (this.tabdate == 1) {
                        this.option.series[1].data.push(data.old_list[i].impressions);
                    }
                    if (this.tabdate == 2) {
                        this.option.series[1].data.push(data.old_list[i].click);
                    }
                    if (this.tabdate == 3) {
                        this.option.series[1].data.push(data.old_list[i].click_rate);
                    }
                }
                this.echart.setOption(this.option);
                window.addEventListener('resize', () => {
                    this.echart.resize();
                });
            },
            deep: true
        }
    },
    methods: {
        // 计算表格总值和均值
        handleSummary (param) {
            const { columns, data } = param;
            const sums = [];
            // 遍历设置每一个单元格数值
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '表尾计算';
                    return;
                }

                // 后端汇总均值处理
                if (this.tableData.params) {
                    for (let i in this.tableData.params.total_data) {
                        if (column.property == i) {
                            sums[index] = this.tableData.params.total_data[i];
                        }
                    }
                }
                return;

                // 前端汇总均值处理

                // // 遍历获取数值
                // const values = data.map(item => Number(item[column.property]));
                // // 判断是否为数字
                // if (!values.every(value => isNaN(value))) {
                // 	// 判断是否为总值
                // 	if (
                // 		column.property == 'spontaneous_sales' ||
                // 		column.property == 'c_g_sales' ||
                // 		column.property == 'actual_sales' ||
                // 		column.property == 'impressions' ||
                // 		column.property == 'order_num' ||
                // 		column.property == 'spend' ||
                // 		column.property == 'output' ||
                // 		column.property == 'click'
                // 	) {
                // 		sums[index] =
                // 			'汇总：' +
                // 			values
                // 				.reduce((prev, curr) => {
                // 					const value = Number(curr);
                // 					if (!isNaN(value)) {
                // 						return prev + curr;
                // 					} else {
                // 						return prev;
                // 					}
                // 				}, 0)
                // 				.toFixed(2);
                // 	} else if (column.property == 'campaign_id') {
                // 		// id不需要计算总值或均值
                // 		sums[index] = '';
                // 	} else {
                // 		// 其余计算均值
                // 		sums[index] =
                // 			'均值：' +
                // 			(
                // 				values.reduce((prev, curr) => {
                // 					const value = Number(curr);
                // 					if (!isNaN(value)) {
                // 						return prev + curr;
                // 					} else {
                // 						return prev;
                // 					}
                // 				}, 0) / this.pageInfo.num
                // 			).toFixed(2);
                // 	}
                // } else {
                // 	// 非数字处理
                // 	sums[index] = '';
                // }
            });
            return sums;
        },
        // 获取三级分类名
        getClassifyList () {
            var loginData = {};
            loginData.version = '1.00';
            loginData.action = 'get_wayfair_sku_classify_all';
            loginData.user_token = localStorage.token;
            loginData.module = 'wayfair_ad_analysis';
            loginData.classify = '';
            this.$api.login.login(loginData).then(res => {
                console.log('res', res);
                if (res.data.status === 1) {
                    this.classificationList = res.data.result;
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            });
        },
        // 搜索
        serchlist () {
            localStorage.setItem('pagenum', 1);
            this.pageInfo.page = Number(localStorage.pagenum);
            // this.handleCurrentChange(1);
            this.getlist();
            this.getlistecharts();
        },
        // 初始化图表
        initEchart () {
            this.echart = this.$echarts.init(document.getElementById('chart-container'));
        },
        // 搜索栏数据处理返回
        searchQueryReturn () {
            let data = {};
            data.page = this.pageInfo.page;
            data.num = this.pageInfo.num;
            data.group_type = this.group_type;
            data.sku = this.sku;
            data.dimension = this.dimension;
            data.classify_name = this.classify_name;
            data.campaign_id = this.campaign_id;
            if (this.dateSelect.length !== 0) {
                data.start_time = parseInt(this.dateSelect[0] / 1000);
                data.end_time = parseInt(this.dateSelect[1] / 1000);
            } else {
                data.start_time = '';
                data.end_time = '';
            }
            if (this.sortQuery.order == 'ascending') {
                data.sort_name = this.sortQuery.prop;
                data.sort = 'asc';
            } else if (this.sortQuery.order == 'descending') {
                data.sort_name = this.sortQuery.prop;
                data.sort = 'desc';
            } else {
                data.sort_name = '';
                data.sort = '';
            }
            return data;
        },
        /** 获取列表数据
         *  sortName: {string} 排序名
         *  sort: {string} 排序顺序
         **/
        getlist () {
            let data = this.searchQueryReturn();
            this.$emit('getlist', data);
        },

        // 获取图表数据
        getlistecharts () {
            this.option.series[0].data = [];
            this.option.xAxis.data = [];
            this.option.series[1].data = [];
            var data = {};
            data.group_type = this.group_type;
            data.sku = this.sku;
            data.classify_name = this.classify_name;
            data.dimension = this.dimension;
            data.campaign_id = this.campaign_id;
            if (this.dateSelect.length !== 0) {
                data.start_time = parseInt(this.dateSelect[0] / 1000);
                data.end_time = parseInt(this.dateSelect[1] / 1000);
            } else {
                data.start_time = '';
                data.end_time = '';
            }

            // if(this.dateSelect.length == 0){
            //     data.start_time = '';
            // data.end_time = '';
            // }else{
            //     data.start_time = parseInt(this.dateSelect[0] / 1000);
            // data.end_time = parseInt(this.dateSelect[1] / 1000);
            // }
            this.$emit('getlistecharts', data);
        },
        // 导出表格
        downloadTable () {
            let data = {};
            data.group_type = this.group_type;
            data.sku = this.sku;
            data.dimension = this.dimension;
            data.classify_name = this.classify_name;
            data.campaign_id = this.campaign_id;
            if (this.dateSelect.length !== 0) {
                data.start_time = parseInt(this.dateSelect[0] / 1000);
                data.end_time = parseInt(this.dateSelect[1] / 1000);
            } else {
                data.start_time = '';
                data.end_time = '';
            }
            this.$emit('downloadTable', data);
        },
        // 点击导入
        clickUpload (v) {
            console.log('v', v);
            this.$emit('clickUpload', v); // 向上传递请求数据
        },
        // 每页显示数量改变
        handleSizeChange (val) {
            console.log('val', val);
            this.pageInfo.num = val;
            this.getlist();
        },
        // 页码切换
        handleCurrentChange (value) {
            localStorage.setItem('pagenum', value);
            this.pageInfo.page = Number(localStorage.pagenum);
            this.getlist();
        },
        tab (v, e) {
            // console.log(v);
            this.isActive = v;
            if (v == '0') {
                this.tabdate = 0;
                this.getlistecharts();
            }
            if (v == '1') {
                this.tabdate = 1;
                this.getlistecharts();
            }
            if (v == '2') {
                this.tabdate = 2;
                this.getlistecharts();
            }
            if (v == '3') {
                this.tabdate = 3;
                this.getlistecharts();
            }
        },
        sortChange ({ column, prop, order }) {
            localStorage.setItem('pagenum', 1);
            this.pageInfo.page = Number(localStorage.pagenum);
            this.sortQuery.prop = prop;
            this.sortQuery.order = order;
            this.getlist();
        },
        handleWidth (column) {
            let width = 0;
            let newWidth = 0;
            // 新建一个 span
            let span = document.createElement('span');
            // 设置表头名称
            span.innerText = column.label;
            // 设置表头class
            span.className = 'getTextWidth';
            // 临时插入 document
            document.querySelector('body').appendChild(span);
            // 获取span标签的宽度
            width = document.querySelector('.getTextWidth').offsetWidth;
            // 重点：获取span最小宽度,设置当前列,注意这里加了20,字段较多时还是有挤压,且渲染后的div内左右padding都是10,所以+20.如果有排序按钮要记得再加上24,排序按钮的宽度是24(可能还有边距/边框等值，需要根据实际情况加上)
            newWidth = width + 50;
            // 移除 document 中临时的 span
            document.querySelector('.getTextWidth').remove();

            return newWidth;
        }
    },
    //   结束清除监听
    beforeDestroy () {
        const _this = this;
        window.removeEventListener('resize', () => {
            _this.echart.resize();
        });
    },
    beforeRouteLeave (to, form, next) {
        to.meta.keepAlive = true;
        next(0);
    }
};
</script>

<style lang="scss" scoped>
.wayfair {
    .downloads-button {
        width: 85px;
        height: 40px;
        overflow: hidden;
        position: relative;

        input {
            width: 85px;
            height: 40px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
        }
    }

    .topSearch {
        margin: 20px 0 10px 0;

        .upload {
            display: inline-block;
        }

        .downloads-button {
            margin-left: 0 !important;
        }
    }

    .bg-purple {
        background: #fff;
        padding: 30px 0;
    }

    #chart-container {
        position: relative;
        height: 40vh;
        overflow: hidden;
    }

    .tableBox {
        width: 100%;
        margin: 10px 0;

        .inDiv {
            // margin: 0 10px;
            // white-space: nowrap;
        }
    }

    .el-select {
        width: 10%;
    }

    .el-input {
        width: 12%;
    }

    .page {
        padding: 10px 0;
        text-align: right;
    }

    .tab {
        width: 100%;
        height: 40px;
        padding: 0 10px;

        .tab-s {
            background-color: #fff;
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: nowrap;

            .tab1:nth-child(1) {
                margin-left: 0;
            }

            .tab1 {
                flex: 1;
                margin-left: 1px;
                padding: 0 20px;
                // border: 1px solid #B5A7E8;
                border-radius: 5px;
                overflow: hidden;
                background-color: #409eff;
                cursor: pointer;
                transition: all 0.6s;
                display: flex;
                align-items: center;
                color: #fff;
                font-style: 18px;
                font-weight: 800;
            }

            .tab1:hover {
                background-color: #b5a7e8;
            }

            .active {
                background-color: #b5a7e8;
            }
        }
    }
}
</style>

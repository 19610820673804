<template>
	<div class="box">
		<!-- <div class="tit">
			<h4>利润测算套装{{title.country}} -- {{ title.platform }}</h4>
		</div> -->
		<div class="addPanel" v-if="pc">
			<svg
				t="1689987403434"
				class="icon icon-add"
				viewBox="0 0 1024 1024"
				version="1.1"
				@click="handleAddParams"
				xmlns="http://www.w3.org/2000/svg"
				p-id="2278"
				width="18"
				height="18"
			>
				<path
					d="M514.048 62.464q93.184 0 175.616 35.328t143.872 96.768 96.768 143.872 35.328 175.616q0 94.208-35.328 176.128t-96.768 143.36-143.872 96.768-175.616 35.328q-94.208 0-176.64-35.328t-143.872-96.768-96.768-143.36-35.328-176.128q0-93.184 35.328-175.616t96.768-143.872 143.872-96.768 176.64-35.328zM772.096 576.512q26.624 0 45.056-18.944t18.432-45.568-18.432-45.056-45.056-18.432l-192.512 0 0-192.512q0-26.624-18.944-45.568t-45.568-18.944-45.056 18.944-18.432 45.568l0 192.512-192.512 0q-26.624 0-45.056 18.432t-18.432 45.056 18.432 45.568 45.056 18.944l192.512 0 0 191.488q0 26.624 18.432 45.568t45.056 18.944 45.568-18.944 18.944-45.568l0-191.488 192.512 0z"
					p-id="2279"
					fill="#1296db"
				></path>
			</svg>
			<div v-for="num in ParamsNum" :key="num">
				<el-row :gutter="20" style="margin-bottom: 10px">
					<el-col :span="3">
						<span class="span"><b>长: </b></span>
						<div class="grid-content bg-purple">
							<el-input
								v-model="temp.params[num - 1].sku_long"
								:placeholder="UnitSwitchings.cm_long"
								size="medium"
							></el-input>
						</div>
					</el-col>
					<el-col :span="3">
						<span class="span"><b>宽:</b></span>
						<div class="grid-content bg-purple">
							<el-input
								v-model="temp.params[num - 1].sku_wide"
								:placeholder="UnitSwitchings.cm_width"
								size="medium"
							></el-input>
						</div>
					</el-col>
					<el-col :span="3">
						<span class="span"><b>高:</b></span>
						<div class="grid-content bg-purple">
							<el-input
								v-model="temp.params[num - 1].sku_high"
								:placeholder="UnitSwitchings.cm_height"
								size="medium"
							></el-input>
						</div>
					</el-col>
					<el-col :span="3">
						<span class="spans"><b>重量:</b></span>
						<div class="grid-content bg-purple">
							<el-input
								v-model="temp.params[num - 1].sku_weight"
								:placeholder="UnitSwitchings.kg_weight"
								size="medium"
							></el-input>
						</div>
					</el-col>
					<el-col :span="3">
						<span class="spanss"><b>成本价:</b></span>
						<div class="grid-content bg-purple">
							<el-input
								v-model="temp.params[num - 1].cost_price"
								placeholder="请输入成本价(￥)"
								size="medium"
							></el-input>
						</div>
					</el-col>
					<el-col :span="3">
						<span class="spanss"><b>售价:</b></span>
						<div class="grid-content bg-purple">
							<el-input
								v-model="temp.params[num - 1].selling_price"
								placeholder="请输入售价($)"
								size="medium"
							></el-input>
						</div>
					</el-col>
					<el-col class="del" :span="4" style="text-align: center; line-height: 30px">
						<i
							class="el-icon-delete-solid"
							style="color: red; cursor: pointer"
							@click="handleDeleteParams(num - 1)"
						></i>
					</el-col>
				</el-row>
			</div>
			<div class="sell-button">
				<div class="block">
					<span class="spans"><b>一级分类:</b></span>
					<el-select placeholder="请选择一级品类名称" v-model="code1" clearable size="medium">
						<el-option
							v-for="(item, index) in jibie1"
							:key="index"
							:value="item.pc_id"
							:label="item.pc_name"
						></el-option>
					</el-select>
				</div>
				<el-button type="danger" @click="UnitSwitching" size="mini"
					>切换单位（{{ UnitSwitchings.a }}）</el-button
				>
			</div>
			<el-button class="button-ji" type="primary" @click="imitate_profit">模拟计算</el-button>
		</div>

		<div class="phone" v-if="phone">
			<svg
				t="1689987403434"
				class="icon phone-icon-add"
				viewBox="0 0 1024 1024"
				version="1.1"
				@click="handleAddParams"
				xmlns="http://www.w3.org/2000/svg"
				p-id="2278"
				width="18"
				height="18"
			>
				<path
					d="M514.048 62.464q93.184 0 175.616 35.328t143.872 96.768 96.768 143.872 35.328 175.616q0 94.208-35.328 176.128t-96.768 143.36-143.872 96.768-175.616 35.328q-94.208 0-176.64-35.328t-143.872-96.768-96.768-143.36-35.328-176.128q0-93.184 35.328-175.616t96.768-143.872 143.872-96.768 176.64-35.328zM772.096 576.512q26.624 0 45.056-18.944t18.432-45.568-18.432-45.056-45.056-18.432l-192.512 0 0-192.512q0-26.624-18.944-45.568t-45.568-18.944-45.056 18.944-18.432 45.568l0 192.512-192.512 0q-26.624 0-45.056 18.432t-18.432 45.056 18.432 45.568 45.056 18.944l192.512 0 0 191.488q0 26.624 18.432 45.568t45.056 18.944 45.568-18.944 18.944-45.568l0-191.488 192.512 0z"
					p-id="2279"
					fill="#1296db"
				></path>
			</svg>
			<div v-for="num in ParamsNum" :key="num" class="phone-add">
				<el-row type="flex" class="row-bg">
					<div class="add-top">
						<el-col :span="11">
							<span class="span"><b>长: </b></span>
							<div class="grid-content bg-purple">
								<el-input
									v-model="temp.params[num - 1].sku_long"
									:placeholder="UnitSwitchings.cm_long"
									size="medium"
								></el-input>
							</div>
						</el-col>
						<el-col :span="11">
							<span class="span"><b>宽:</b></span>
							<div class="grid-content bg-purple-light">
								<el-input
									v-model="temp.params[num - 1].sku_wide"
									:placeholder="UnitSwitchings.cm_width"
									size="medium"
								></el-input>
							</div>
						</el-col>
					</div>
					<div class="add-center">
						<el-col :span="11">
							<span class="span"><b>高:</b></span>
							<div class="grid-content bg-purple">
								<el-input
									v-model="temp.params[num - 1].sku_high"
									:placeholder="UnitSwitchings.cm_height"
									size="medium"
								></el-input>
							</div>
						</el-col>
						<el-col :span="11">
							<span class="spans"><b>重量:</b></span>
							<div class="grid-content bg-purple">
								<el-input
									v-model="temp.params[num - 1].sku_weight"
									:placeholder="UnitSwitchings.kg_weight"
									size="medium"
								></el-input>
							</div>
						</el-col>
					</div>
					<div class="add-bottom">
						<el-col :span="12">
							<span class="spanss"><b>成本价:</b></span>
							<div class="grid-content bg-purple">
								<el-input
									v-model="temp.params[num - 1].cost_price"
									placeholder="请输入成本价"
									size="medium"
								></el-input>
							</div>
						</el-col>
						<el-col :span="12">
							<span class="spanss"><b>售价:</b></span>
							<div class="grid-content bg-purple">
								<el-input
									v-model="temp.params[num - 1].selling_price"
									placeholder="请输入成本价"
									size="medium"
								></el-input>
							</div>
						</el-col>

						<el-col class="del" :span="4" style="text-align: center; line-height: 30px">
							<i
								class="el-icon-delete-solid"
								style="color: red; cursor: pointer"
								@click="handleDeleteParams(num - 1)"
							></i>
						</el-col>
					</div>
				</el-row>
			</div>
			<div class="phone-button">
				<div class="phone-block">
					<span class="spans"><b>一级分类:</b></span>
					<el-select placeholder="请选择一级品类名称" v-model="code1" clearable size="medium">
						<el-option
							v-for="(item, index) in jibie1"
							:key="index"
							:value="item.pc_id"
							:label="item.pc_name"
						></el-option>
					</el-select>
				</div>
				<el-button type="danger" @click="UnitSwitching" size="mini">切换单位({{ UnitSwitchings.a }})</el-button>
			</div>
			<el-button class="button-ji" type="primary" @click="imitate_profit" size="mini">模拟计算</el-button>
		</div>

		<div class="title">
			<el-table :data="tableData" border style="width: 100%" fit:true highlight-current-row:true :size="sizes">
				<el-table-column
					v-for="(item, index) in tableColumn"
					:key="index"
					:prop="item.prop"
					:label="item.label"
					:min-width="handleWidth(item)"
				></el-table-column>
			</el-table>
		</div>
		<div class="addPanel addPanels" v-if="pc">
			<el-row :gutter="20" style="margin-bottom: 10px">
				<el-col class="el-div" :span="3">
					<span class="span-title"><b>海运费: </b></span>
					<div class="span-input">
						<el-input v-model="oceanFreight" placeholder="请输入长(inch)" size="mini"></el-input>
					</div>
				</el-col>
				<el-col class="el-div" :span="3">
					<span class="span-title"><b>汇率: </b></span>
					<div class="span-input">
						<el-input v-model="exchangeRate" placeholder="请输入长(inch)" size="mini"></el-input>
					</div>
				</el-col>
				<el-col class="el-div" :span="3">
					<span class="span-title"><b>佣金费率: </b></span>
					<div class="span-input">
						<el-input v-model="commissionRate" placeholder="请输入长(inch)" size="mini"></el-input>
					</div>
					<span class="span-titles"> %</span>
				</el-col>
				<el-col class="el-div" :span="3">
					<span class="span-title"><b>仓储费率: </b></span>
					<div class="span-input">
						<el-input v-model="storageFeeRate" placeholder="请输入长(inch)" size="mini"></el-input>
					</div>
					<span class="span-titles"> %</span>
				</el-col>
				<el-col class="el-div" :span="3">
					<span class="span-title"><b>广告费率: </b></span>
					<div class="span-input">
						<el-input v-model="advertisingExpensesRate" placeholder="请输入长(inch)" size="mini"></el-input>
					</div>
					<span class="span-titles"> %</span>
				</el-col>
				<el-col class="el-div" :span="3">
					<span class="span-title"><b>售后费率: </b></span>
					<div class="span-input">
						<el-input v-model="afterSalesFeesRate" placeholder="请输入长(inch)" size="mini"></el-input>
					</div>
					<span class="span-titles"> %</span>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		// 表头内容
		tableColumn: {
			type: Array,
			default: []
		},
		//  列表数据
		tableData: {
			type: Array,
			default: {}
		},
        title:{
            type:Object,
            default:''
        }
	},
	data() {
		return {
			pc: '', //电脑
			phone: '', //手机
			sizes: '', //手机
			total: 0,
			temp: {
				params: [
					{
						sku_long: '',
						sku_high: '',
						sku_weight: '',
						sku_wide: '',
						cost_price: ''
					}
				]
			},
			ParamsNum: 1,
			jibie1: [],
			productData: [],
			selling_price: '',
			code1: '',
			oceanFreight: 6000,
			exchangeRate: 6.3,
			commissionRate: 15,
			storageFeeRate: 3,
			advertisingExpensesRate: 10,
			afterSalesFeesRate: 5,
			UnitSwitchings: {
				cm_long: '请输入长(inch)',
				cm_width: '请输入宽(inch)',
				cm_height: '请输入高(inch)',
				kg_weight: '请输入重量(lbs)',
				switch: '1',
				a: 'inch'
			}
		};
	},
	created() {
		this.category();
		this.ifwidth();
	},
	// 保存状态
	beforeRouteLeave(to, form, next) {
		to.meta.keepAlive = true;
		next(0);
	},
	methods: {
		UnitSwitching() {
			if (this.UnitSwitchings.switch == '1') {
				this.UnitSwitchings.cm_long = '请输入长(cm)';
				this.UnitSwitchings.cm_width = '请输入宽(cm)';
				this.UnitSwitchings.cm_height = '请输入高(cm)';
				this.UnitSwitchings.kg_weight = '请输入重量(kg)';
				this.UnitSwitchings.switch = '2';
				this.UnitSwitchings.a = 'cm';
			} else {
				this.UnitSwitchings.cm_long = '请输入长(inch)';
				this.UnitSwitchings.cm_width = '请输入宽(inch)';
				this.UnitSwitchings.cm_height = '请输入高(inch)';
				this.UnitSwitchings.kg_weight = '请输入重量(lbs)';
				this.UnitSwitchings.a = 'inch';
				this.UnitSwitchings.switch = '1';
			}
		},
		ifwidth() {
			if (window.screen.width <= 750) {
				this.pc = false;
				this.phone = true;
				this.rig = false;
				this.sizes = 'mini';
			} else {
				this.pc = true;
				this.phone = false;
				this.rig = 'right';
				this.sizes = 'medium';
			}
		},
		// 列宽度
		handleWidth(column) {
			let width = 0;
			let newWidth = 0;
			// 新建一个 span
			let span = document.createElement('span');
			// 设置表头名称
			span.innerText = column.label;
			// 设置表头class
			span.className = 'getTextWidth';
			// 临时插入 document
			document.querySelector('body').appendChild(span);
			// 获取span标签的宽度
			width = document.querySelector('.getTextWidth').offsetWidth;
			// 重点：获取span最小宽度,设置当前列,注意这里加了20,字段较多时还是有挤压,且渲染后的div内左右padding都是10,所以+20.如果有排序按钮要记得再加上24,排序按钮的宽度是24(可能还有边距/边框等值，需要根据实际情况加上)
			newWidth = width + 50;
			// 移除 document 中临时的 span
			document.querySelector('.getTextWidth').remove();

			return newWidth;
		},
		imitate_profit() {
			let data = this.TransmissionOfAnalogData();
			this.$emit('imitate_profit', data);
		},
		TransmissionOfAnalogData() {
			this.productData = [...this.temp.params];
			let data = {};
			data.code1 = this.code1;
			data.productData = JSON.stringify(this.productData);
			data.ocean_freight = this.oceanFreight;
			data.exchange_rate = this.exchangeRate;
			data.commission_rate = this.commissionRate;
			data.storage_fee_rate = this.storageFeeRate;
			data.advertising_expenses_rate = this.advertisingExpensesRate;
			data.after_sales_fees_rate = this.afterSalesFeesRate;
			data.inch_cm = this.UnitSwitchings.switch;
			return data;
		},

		handleAddParams() {
			this.temp.params.push({ sku_long: '' });
			this.ParamsNum++;
		},
		handleDeleteParams(num) {
			if (this.temp.params.length == 1) return;
			this.temp.params.splice(num, 1);
			this.ParamsNum--;
		},
		category() {
			var loginData = {};
			loginData.version = '1.00';
			loginData.action = 'get_all_goods_class_list_1';
			loginData.user_token = localStorage.token;
			loginData.module = 'goods_main';
			this.$api.login.login(loginData).then(res => {
				// console.log(res);
				if (res.data.status === 1) {
					this.jibie1 = res.data.result;
				}
			});
		}
	},
	mounted() {}
};
</script>
<style lang="scss" scoped>
.tit {
	margin-bottom: 10px;
}

.el-row {
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
}

.el-col-3 {
	width: 13.5%;
}

.el-col {
	border-radius: 4px;
	display: flex;
	align-items: center;

	.span {
		width: 15%;
	}

	.spans {
		width: 28%;
	}

	.spanss {
		width: 40%;
	}
}

.bg-purple-dark {
	background: #99a9bf;
}

.bg-purple {
	background: #d3dce6;
	display: flex;
	align-items: center;
}

.bg-purple-light {
	background: #e5e9f2;
}

.grid-content {
	border-radius: 4px;
	min-height: 36px;
}

.row-bg {
	padding: 10px 0;
	background-color: #f9fafc;
}

.addPanel {
	margin: 0 0 20px 0;
	position: relative;
	margin-left: 10px;
	width: 98%;
	min-height: 70px;
	border: 2px solid #e4e7ed;
	border-radius: 15px;
	padding: 10px;

	.button-ji {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.icon-add {
		position: absolute;
		bottom: 43px;
		left: 81.5%;
		cursor: pointer;
	}

	// .el-cascader{
	//   width: 10%;
	// }
	.del {
		width: 5%;
	}
}

.addPanels {
	margin: 0;
	height: 40px;
	min-height: 60px;

	.el-div {
		.span-title {
			width: 50%;
		}

		.span-input {
		}

		.span-titles {
			width: 10%;
		}
	}
}

.addBlock {
	line-height: 50px;
	border: 1px #dddddd dashed;
	padding: 10px;
	cursor: pointer;
}

.sell-button {
	display: flex;
	justify-content: flex-start;

	.block {
		display: flex;
		align-items: baseline;
		margin-right: 10px;

		.span {
			width: 40px;
		}

		.spans {
			width: 64px;
		}
	}
}

@media (max-width: 750px) {
	.phone {
		overflow: hidden;

		.phone-add {
			.row-bg {
				flex-wrap: wrap;
				width: 100%;
				height: 150px;

				.add-top {
					width: 100%;

					.el-col {
						.span {
							width: 50px;
							margin: 0 0 0 10px;
						}
					}
				}

				.add-center {
					width: 100%;

					.el-col {
						.span {
							width: 50px;
							margin: 0 0 0 10px;
						}

						.spans {
							width: 65px;
							margin: 0 0 0 10px;
						}
					}
				}

				.add-bottom {
					width: 100%;

					.el-col {
						.spanss {
							width: 85px;
							margin: 0 0 0 10px;
						}
					}
				}
			}
		}

		.phone-button {
			display: flex;
			margin: 10px 0px;

			.phone-block {
				display: flex;
				align-items: center;

				.span {
					width: 50px;
				}

				.spans {
					width: 105px;
				}
			}
		}

		.el-button {
			float: right;
			margin-bottom: 10px;
		}
	}
}
</style>

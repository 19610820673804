export default function (routingTable = []) {
	//创建路由配置模板
	let router = {
		path: '/',
		component: () => import('../views/index.vue'),
		meta: { keepAlive: true },
		children: []
	};
	//递归
	let IsChildren = list => {
		return list.map(c => {
			return {
				path: c.name_en + '',
				name: c.name,
				component: () => import(`../components/${c.name_en}.vue`),
				meta: { keepAlive: true, last: c.list && c.list.length !== 0 ? false : true },
				children: c.list ? IsChildren(c.list) : []
			};
		});
	};
	//更改路由表数据结构
	let router1 = routingTable.map(item => {
		return {
			path: item.name_en + '',
			component: () => import(`../components/${item.name_en}.vue`),
			meta: {
				keepAlive: true,
				affix: item.name == '首页' ? true : false,
				last: item.list && item.list.length !== 0 ? false : true
			},
			name: item.name,
			children: item.list ? IsChildren(item.list) : []
		};
	});
	// console.log(router1);
	router1.push({
		path: 'salesstatistics',
		name: '商品销售统计',
		children: [],
		component: () => import(`../components/salesstatistics.vue`),
		meta: { keepAlive: true }
	});
	router1.push({
		path: 'earthOrderReport',
		name: '地区排行榜',
		children: [],
		component: () => import('../components/earthOrderReport')
	});
	router1.push({
		path: 'amazon_daily',
		name: '亚马逊',
		children: [],
		component: () => import('../components/amazon_daily')
	});
	router1.push({
		path: 'sales_weekly',
		name: '销量周报',
		children: [],
		component: () => import('../components/sales_weekly')
	});
	router1.push({
		path: 'sku_identity/sku_identity_sell_info_week',
		name: 'sku销量周报',
		children: [],
		component: () => import('../components/sku_identity_sell_info_week')
	});

	router1.push({
		path: 'sales_monthly',
		name: '销量月报',
		children: [],
		component: () => import('../components/sales_monthly')
	});
	router1.push({
		path: 'regional_sales',
		name: '区域销量完成率',
		children: [],
		component: () => import('../components/regional_sales')
	});
    
	router1.push({
		path: '/redirect',
		component: () => import('../components/redirect'),
		hidden: true,
		children: [
			{
				path: '/redirect/:path*',
				component: () => import('../components/redirect')
			}
		]
	});
	router1.push({
		path: 'limitsOfAuthority',
		name: '设置个人权限',
		children: [],
		component: () => import(`../components/limitsOfAuthority.vue`)
	});
	//添加路由表进模板
	for (let i = 0; i < router1.length; i++) {
		if (router1[i].path === 'set_up') {
			router1[i].children.push({
				path: 'limits',
				name: 'limits',
				component: () => import(`../components/limits.vue`),
				meta: { keepAlive: true }
			});
			// for (let j = 0; j < router1[i].children.length; j++) {
			// 	if (router1[i].children[j].path === 'user') {
			// 		router1[i].children[j].children.push({
			// 			path: 'limitsOfAuthority',
			// 			name: '设置权限',
			// 			component: () => import(`../components/limitsOfAuthority.vue`)
			// 		});
			// 	}
			// }
		}
		router1[i].children.filter(item => {
			if (item.path === 'supplier_stock') {
				router1[i].children.push({
					path: 'log',
					name: '日志',
					component: () => import(`../components/log.vue`)
				});
                router1[i].children.push({
                    path: 'supplierStockWarehousingListDetail',
                    name: '采购出入库单详情',
                    component: () => import('../components/supplierStockWarehousingListDetail')
                });
                router1[i].children.push({
                    path: 'supplierStockWarehousingList',
                    name: '采购出入库单',
                    component: () => import('../components/supplierStockWarehousingList')
                });
			}
		});
        router1[i].children.filter(item => {
			if (item.path === 'need_order_list') {
				router1[i].children.push({
                    path: 'demandOrder',
                    name: '采购订单详情',
                    component: () => import('../components/demandOrder')
                });
                router1[i].children.push({
                    path: 'applyPurchase',
                    name: '采购调拨申请',
                    component: () => import('../components/applyPurchase')
                });
                router1[i].children.push({
                    path: 'applyPurchaseLog',
                    name: '采购调拨日志',
                    component: () => import('../components/applyPurchaseLog')
                });
			}
		});
        router1[i].children.filter(item => {
			if (item.path === 'cabinet_arrangement_result_new_fen') {
				router1[i].children.push({
                    path: 'shopCodeList',
                    name: '店铺代码列表',
                    component: () => import('../components/shopCodeList')
                });
			}
		});
		router.children.push(router1[i]);
	}
	//添加更改好的路由表进路由配置
	this.$router.addRoute(router);
	// console.log(router);
	//添加404页面进路由配置
	this.$router.addRoute({
		path: '*',
		component: () => import('../components/404.vue')
	});
}

<template>
    <div class="box">
        <!-- <div class="tit">
            <h4>利润测算列表{{ title.country }}--{{ title.country }}</h4>
        </div> -->
        <!-- 搜索框 -->
        <div class="add" v-if="pc">
            <div class="inp">
                <div class="inp-top">
                    <el-input placeholder="请输入平台sku" v-model.trim="platform_skuSearch" clearable
                        @keyup.enter.native="getserchlist(1)">
                    </el-input>
                    <el-input placeholder="请输入仓库sku" v-model.trim="product_sku" clearable
                        @keyup.enter.native="getserchlist(1)">
                    </el-input>
                    <!-- <div class="sku"> -->
                    <!-- <span>测算人： </span> -->
                    <el-select placeholder="请选择测算人" v-model="user_id" clearable filterable>
                        <el-option v-for="(item, index) in user_id1" :key="index" :value="item.id"
                            :label="item.nickname"></el-option>
                    </el-select>
                    <!-- </div> -->
                    <el-select v-model="type" placeholder="请选择类型" clearable style="margin-right: 10px">
                        <el-option v-for="(item, index) in type1" :key="index" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-date-picker :clearable="false" v-model="time" type="datetimerange" align="right"
                        start-placeholder="测算开始日期" end-placeholder="测算结束日期" :default-time="['00:00:00', '00:00:00']"
                        @change="times($event)" value-format="timestamp" style="margin-right: 10px">
                    </el-date-picker>
                    <el-button type="primary" icon="el-icon-search" @click="getserchlist(1)">搜索</el-button>
                </div>
            </div>
            <div class="divright">
                <el-button type="info" @click="downs()" :disabled="false" size="mini">模板<i
                        class="el-icon-download"></i></el-button>

                <el-button type="warning" class="downloads-button">
                    导入
                    <input type="file" @change="importxlsx($event)" />
                </el-button>
                <el-button type="success" @click="down()">导出<i class="el-icon-download"></i></el-button>
                <el-button type="primary" @click="add_api(true)">添加</el-button>
            </div>
        </div>

        <div class="phone" v-if="phone">
            <div class="phone-inp">
                <div class="phone-inp-top">
                    <el-input placeholder="请输入平台sku" v-model.trim="platform_skuSearch" clearable
                        style="margin: 0 10px 10px 0" @keyup.enter.native="getserchlist(1)" size="mini">
                    </el-input>
                    <el-input placeholder="请输入仓库sku" v-model.trim="product_sku" clearable
                        @keyup.enter.native="getserchlist(1)" size="mini">
                    </el-input>
                    <el-select v-model="type" placeholder="请选择类型" clearable style="margin: 0 10px 10px 0" size="mini">
                        <el-option v-for="(item, index) in type1" :key="index" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>

                    <el-select placeholder="请选择测算人" v-model="user_id" clearable size="mini">
                        <el-option v-for="(item, index) in user_id1" :key="index" :value="item.id"
                            :label="item.nickname"></el-option>
                    </el-select>

                    <el-date-picker v-model="star_time" type="date" placeholder="选择开始日期" style="margin: 0 10px 10px 0"
                        size="mini">
                    </el-date-picker>

                    <el-date-picker v-model="end_time" type="date" placeholder="选择结束日期" size="mini">
                    </el-date-picker>
                </div>
                <div class="phone-inp-bottom">
                    <el-button type="primary" icon="el-icon-search" @click="getserchlist(1)" size="mini">搜索</el-button>
                    <el-button type="info" @click="downs()" :disabled="false" size="mini">模板<i
                            class="el-icon-download"></i></el-button>
                    <el-button type="warning" class="el-icon-upload downloads-button" size="mini">
                        导入
                        <input type="file" @change="importxlsx($event)" ref="file" />
                    </el-button>
                    <el-button type="primary" @click="add_api(true)" size="mini">添加</el-button>
                </div>
            </div>
        </div>

        <el-dialog title="利润测算" :visible.sync="dialogFormVisible" :width="dialogWidth">
            <el-form :model="form" id="phone-form">
                <el-form-item label="售价" :label-width="formLabelWidth">
                    <el-input placeholder="请输入售价" v-model="selling_price" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="仓库:" :label-width="formLabelWidth">
                    <el-select placeholder="请选择仓库" v-model.trim="warehouse_substance_id" clearable>
                        <el-option v-for="(item, index) in warehouse_substance_id1" :key="index" :value="item.id"
                            :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="测算模式:" :label-width="formLabelWidth">
                    <el-radio-group v-model="radio">
                        <el-radio :label="2">多箱</el-radio>
                        <el-radio :label="3">套装</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="平台sku:" :label-width="formLabelWidth">
                    <el-select class="elselect" placeholder="请选择平台sku" v-model="form.platform_sku" filterable clearable
                        :filter-method="dataFilter" @change="sku($event)">
                        <el-option v-for="(item, index) in platform_sku1" :key="index" :value="item.sku"
                            :label="item.productSku"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="海运费" :label-width="formLabelWidth">
                    <el-input placeholder="请输入海运费" v-model="form.oceanFreight" autocomplete="off"></el-input> %
                </el-form-item>
                <el-form-item label="汇率" :label-width="formLabelWidth">
                    <el-input placeholder="请输入汇率" v-model="form.exchangeRate" autocomplete="off"></el-input> %
                </el-form-item>
                <el-form-item label="佣金费率" :label-width="formLabelWidth">
                    <el-input placeholder="请输入佣金费率" v-model="form.commissionRate" autocomplete="off"></el-input>
                    %
                </el-form-item>
                <el-form-item label="仓储费率" :label-width="formLabelWidth">
                    <el-input placeholder="请输入仓储费率" v-model="form.storageFeeRate" autocomplete="off"></el-input>
                    %
                </el-form-item>
                <el-form-item label="广告费率" :label-width="formLabelWidth">
                    <el-input placeholder="请输入广告费率" v-model="form.advertisingExpensesRate"
                        autocomplete="off"></el-input>
                    %
                </el-form-item>
                <el-form-item label="售后费率" :label-width="formLabelWidth">
                    <el-input placeholder="请输入售后费率" v-model="form.afterSalesFeesRate" autocomplete="off"></el-input>
                    %
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addlist">提 交</el-button>
            </div>
        </el-dialog>

        <div class="title">
            <el-table @cell-dblclick="cellDbClick" :data="tableData" border style="width: 100%" fit:true
                highlight-current-row:true height="620" :size="sizes">
                <el-table-column v-for="(item, index) in tableColumn" :key="index" :label="item.label"
                    :prop="item.prop"></el-table-column>


                <el-table-column :fixed="rig" label="操作" width="110">
                    <template slot-scope="scope">
                        <el-button @click="del(scope.row)" type="danger" size="mini" icon="el-icon-delete"
                            style="margin-left: 10px" :disabled="dele">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- 分页 -->
        <el-pagination v-if="phone" :page-size="queryInfo.pagesize" background layout="prev, pager, next" :total="total"
            @current-change="handleCurrentChange" :current-page="this.queryInfo.pagenum" :small="smalls">
        </el-pagination>

        <div class="pag" v-if="pc">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="this.queryInfo.pagenum" :page-sizes="[10, 30, 50, 70, 90, 100]"
                :page-size="queryInfo.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        tableColumn: {
            type: Array,
            default: []
        },
        tableData: {
            type: Array,
            default: []
        },
        title:{
            type:Object,
            default:{}
        },
        // queryInfo:{
        //     type:Object,
        //     default:{}
        // }

    },
    data () {
        return {
            pc: '', //电脑
            phone: '', //手机
            rig: '', //faang
            sizes: '', //faang
            pingtai: '', //faang
            p: '', //faang
            k: '', //faang
            c: '', //faang
            dialogWidth: 0,
            limitNum: 1, // 上传excell时，同时允许上传的最大数
            fileList: [], // excel文件列表
            content: '',
            queryInfo: {
                pagenum: 1,
                pagesize: 10 // 每页显示多少条数据
            },
            total: 0,
            addProduct_sku: {
                platform_sku: '',
                product_sku: []
            },
            platform_skuSearch: '', //搜索名
            product_sku: '', //搜索名
            type: '',
            type1: [
                {
                    label: '自发货',
                    value: '1'
                },
                {
                    label: '送FBA',
                    value: '2'
                }
            ],
            dialogFormVisible: false, //添加弹出框
            editBox: false, //添加弹出框
            form: {
                platform_sku: '',
                oceanFreight: 6000,
                exchangeRate: 6.3,
                commissionRate: 15,
                storageFeeRate: 3,
                advertisingExpensesRate: 10,
                afterSalesFeesRate: 5
            },
            formLabelWidth: '120px',
            roleid: [],
            role_arr: {},
            editid: '',
            is_del: 1,
            // fileList: [],
            centerDialogVisible: false,
            time: [],
            star_time: '',
            end_time: '',
            radio: 1,
            sort_str: 'update_time',
            dele: '', //判断删除权限状态
            add: '', //判断添加权限状态
            edit_function: '', //判断编辑状态
            download: '', //下载
            upload: '', //上传
            user_id: '',
            user_id1: [],
            user_id2: {},
            user_id3: {},
            warehouse_substance_id: '',
            warehouse_substance_id1: [],
            platform_sku: [],
            sbs: '',
            platform_sku1: [],
            selling_price: '',
            warehouse_substance_name: {},
            formproduct: {},
            cangkusku: [],
            cangkuskus: [],
            radio: 2,
            ptsku: '',
            zhtz: '',
            fileinput: '',
            ocean_freight: '',
            exchange_rate: '',
            commission_rate: '',
            storage_fee_rate: '',
            advertising_expenses_rate: '',
            after_sales_fees_rate: '',
            a: ''
        };
    },
    created () {
        this.warehouse_substance();
        this.userid();
        this.user_id = localStorage.user_id;
        this.ifwidth();
        this.setDialogWidth();
    },
    mounted () {
        window.onresize = () => {
            return (() => {
                this.setDialogWidth();
            })();
        };
    },
    methods: {
        setDialogWidth () {
            //   console.log(document.body.clientWidth)
            let val = document.body.clientWidth;
            const def = 800; // 默认宽度
            if (val < def) {
                this.dialogWidth = '100%';
            } else {
                this.dialogWidth = def + 'px';
            }
        },
        ifwidth () {
            if (window.screen.width <= 750) {
                this.pc = false;
                this.phone = true;
                this.rig = false;
                this.sizes = 'mini';
                this.pingtai = '70';
                this.p = '60';
                this.k = '60';
                this.c = '60';
            } else {
                this.pc = true;
                this.phone = false;
                this.rig = 'right';
                this.sizes = 'medium';
                this.pingtai = '200';
                this.p = '85';
                this.k = '100';
                this.c = '110';
            }
        },
        userid () {
            this.fase = true;
            var loginData = {};
            loginData.version = '1.00';
            loginData.action = 'get_all_user_list';
            loginData.user_token = localStorage.token;
            loginData.module = 'admin_manager';
            loginData.user_id = this.user_id;
            this.$api.login.login(loginData).then(res => {
                // console.log(res, 'wwwwwwwww');

                if (res.data.status === 1) {
                    this.user_id1 = res.data.result;
                    // this.getlist();
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
                this.fase = false;
            });
        },
        // 传值搜索
        getserchlist(){
            let data = this.getData()
            console.log(data);
            this.$emit('getserchlist',data)
        },
        // 搜索函数
        getData () {
            console.log(111);
            let data = {}
            if (!isNaN(parseFloat(this.user_id))) {
                data.user_id = this.user_id;
            } else {
                data.user_id = this.user_id3[this.user_id + ''];
            }
            data.platform_sku = this.platform_skuSearch;
            data.product_sku = this.product_sku;
            data.type = this.type;
            data.created_start = this.star_time;
            data.created_end = this.end_time;
            console.log(data);
           return data
        },
        handleCurrentChange (value) {
            localStorage.setItem('pagenum', value);
            this.queryInfo.pagenum = Number(localStorage.pagenum);
            this.getserchlist(value);
        },
        handleSizeChange (val) {
            this.queryInfo.pagesize = val;
            this.getserchlist();
        },
        del (val) {
            //   console.log(val, "删除");
            this.$confirm('确定删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    var loginData = {};
                    loginData.version = '1.00';
                    loginData.action = 'delete_profit_calculation';
                    loginData.user_token = localStorage.token;
                    loginData.module = 'profit_calculation';
                    loginData.version = '1.00';
                    loginData.group_id = val.group_id;
                    this.$api.login.login(loginData).then(res => {
                        // console.log(res, "删除产品库存");
                        if (res.data.status === 1) {
                            this.$message({
                                message: '删除成功',
                                type: 'success'
                            });
                            // this.getlist();
                        } else {
                            this.$message({
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
        },
        times (time) {
            if (time) {
                //this.time为绑定的数据
                this.star_time = parseInt(this.time[0] / 1000);
                this.end_time = parseInt(this.time[1] / 1000);
            } else {
                this.time = ['0000-00-00 00:00:00', '0000-00-00 00:00:00'];
                this.star_time = '';
                this.end_time = '';
            }
        },
        input (v) {
            //   console.log(v);
            const status_arr = {
                1: 'update_time',
                2: 'num_desc',
                3: 'num_asc'
            };
            v = status_arr[v + ''];
            //   console.log(v);
            this.sort_str = v;
            // this.getlist();
        },

        add_api (add_flag) {
            if (add_flag) {
                this.platform_sku = [];
                this.dialogFormVisible = true;
            }
        },
        //添加事件
        addlist () {
            // this.platform_sku = Array.from(this.platform_sku)
            var loginData = {};
            loginData.version = '1.00';
            loginData.action = 'compute_profit_calculation';
            loginData.user_token = localStorage.token;
            loginData.module = 'profit_calculation';
            loginData.platform_sku = this.ptsku;
            loginData.product_skus = this.form.platform_sku;
            loginData.selling_price = this.selling_price;
            loginData.warehouse_substance_id = this.warehouse_substance_id;
            loginData.warehouse_substance_name =
                this.warehouse_substance_name[this.warehouse_substance_id + ''] == undefined
                    ? (this.warehouse_substance_name = '')
                    : this.warehouse_substance_name;
            if (this.radio == 3) {
                loginData.type = this.radio;
                loginData.number = JSON.stringify(this.zhtz);
            } else {
                loginData.type = this.radio;
            }
            loginData.ocean_freight = this.form.oceanFreight;
            loginData.exchange_rate = this.form.exchangeRate;
            loginData.commission_rate = this.form.commissionRate;
            loginData.storage_fee_rate = this.form.storageFeeRate;
            loginData.advertising_expenses_rate = this.form.advertisingExpensesRate;
            loginData.after_sales_fees_rate = this.form.afterSalesFeesRate;
            this.$api.login.login(loginData).then(res => {
                // console.log(res);

                if (res.data.status === 1) {
                    for (var i in res.data.result) {
                        res.data.result[i].created = dateFormat(res.data.result[i].created * 1000);
                    }
                    // this.tableData = [];
                    this.tableData = res.data.result;
                    // console.log(this.tableData);
                    this.total = res.data.result.total;
                    // this.getlist();
                    this.dialogFormVisible = false;
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            });
        },
        // 仓库sku
        dataFilter (val) {
            //   console.log(typeof (val));
            // let a;
            // a = val.charAt(val.length - 1)
            // val = val.substring(0, val.length - 1);
            // val = val + a
            // console.log(val);
            if (val.length > 2) {
                this.platform_sku = val;
                this.getData();
            }
        },
        // getData () {
        //     var loginData = {};
        //     loginData.version = '1.00';
        //     loginData.action = 'get_all_goods_main_list';
        //     loginData.user_token = localStorage.token;
        //     loginData.module = 'goods_main';
        //     loginData.productSku = this.platform_sku;
        //     loginData.type = this.radio;
        //     this.$api.login.login(loginData).then(res => {
        //         // console.log(res);

        //         if (res.data.status === 1) {
        //             // res.data.result.list.forEach(item => {
        //             //   console.log(item.product_sku);
        //             //   res.data.result.list.product_sku[0] = item.product_sku.join(',')
        //             //   console.log( item.product_sku.join(','));
        //             // });
        //             // for (let i = 0; i < res.data.result.list.length; i++) {
        //             //   res.data.result.list.product_sku[i] =res.data.result.list.product_sku[i].join(',')
        //             // }
        //             this.platform_sku1 = res.data.result.list;
        //             //   console.log(this.platform_sku1);
        //             this.platform_sku1.forEach(item => {
        //                 // console.log(item);
        //                 this.formproduct[item.platform_sku] = item.product_sku;
        //             });
        //             // for (let i in this.platform_sku1) {
        //             //   this.formproduct[this.platform_sku1[i].platform_sku] =this.platform_sku1[i].product_sku;
        //             //     }
        //             //   console.log(this.formproduct);
        //         } else {
        //             this.$message({
        //                 message: res.data.msg,
        //                 type: 'error'
        //             });
        //         }
        //     });
        // },
        sku (val) {
            //   console.log(val);
            if (this.radio == 2) {
                let phpnumber = {};
                // 找到选中项的整个object对象
                phpnumber = this.platform_sku1.find(item => {
                    return item['sku'] === val;
                });
                // 获取对应的number属性
                this.ptsku = phpnumber.platform_sku;
                // console.log(this.ptsku);
            } else {
                let phpnumber = {};
                // 找到选中项的整个object对象
                phpnumber = this.platform_sku1.find(item => {
                    //   console.log(item);
                    return item['sku'] === val;
                });
                // 获取对应的number属性
                this.ptsku = phpnumber.platform_sku;
                this.zhtz = phpnumber.number;
                // console.log(this.zhtz);
            }
        },
        // 仓库数据默认拉取
        warehouse_substance () {
            var loginData = {};
            loginData.version = '1.00';
            loginData.action = 'get_all_warehouse_substance_list';
            loginData.user_token = localStorage.token;
            loginData.module = 'warehouse_substance';
            loginData.countryEn = 'US';
            this.$api.login.login(loginData).then(res => {
                // console.log(res,'仓库');
                this.warehouse_substance_id1 = res.data.result;

                for (let i in res.data.result) {
                    this.warehouse_substance_name[res.data.result[i].id] = res.data.result[i].name;
                }
                // console.log(this.warehouse_substance_name);
            });
        },
        downs () {
            let unixtime = parseInt(new Date().getTime() / 1000 + '');
            let can = {
                version: '1.00',
                action: 'download_profit_calculation_demo',
                module: 'profit_calculation',
                user_token: localStorage.token,
                unixtime: unixtime
            };
            let shu = this.$mdk.mdk(can);
            let location_strs =
                '//pretest.ziyehuwai.com/api?version=1.00&action=download_profit_calculation_demo&module=profit_calculation&user_token=' +
                localStorage.token +
                '&unixtime=' +
                unixtime +
                '&mdf=' +
                shu;
            window.location = location_strs;
        },
        importxlsx (v) {
            let unixtime = parseInt(new Date().getTime() / 1000 + '');
            var loginData = {};
            loginData.version = '1.00';
            loginData.action = 'import_profit_calculation_data';
            loginData.user_token = localStorage.token;
            loginData.module = 'profit_calculation';
            loginData.unixtime = unixtime;
            //   console.log(v);
            let mdfs = this.$mdk.mdk(loginData);
            this.$ImportXlsx.ImportXlsx(v, loginData, mdfs);
        },
        // 下载
        down () {
            //   console.log(this.user_id1);
            let unixtime = parseInt(new Date().getTime() / 1000 + '');
            let arr = this.user_id1;
            const buildObject = arr => {
                const obj = {};
                for (let i = 0; i < arr.length; i++) {
                    const { nickname, id } = arr[i];
                    obj[nickname] = id;
                }
                return obj;
            };
            this.user_id = buildObject(arr)[this.user_id + ''];
            //   console.log(buildObject(this.user_id1));
            if (this.user_id !== localStorage.user_id) {
                if (
                    !this.platform_skuSearch == '' ||
                    !this.product_sku == '' ||
                    !this.type == '' ||
                    !this.star_time == '' ||
                    !this.end_time == ''
                ) {
                    let can = {
                        version: '1.00',
                        action: 'download_profit_calculation',
                        module: 'profit_calculation',
                        user_token: localStorage.token,
                        unixtime: unixtime,
                        platform_sku: this.platform_skuSearch,
                        product_sku: this.product_sku,
                        type: this.type,
                        created_start: this.star_time,
                        created_end: this.end_time,
                        user_id: this.user_id
                    };
                    let shu = this.$mdk.mdk(can);
                    let location_strs =
                        '//pretest.ziyehuwai.com/api?version=1.00&action=download_profit_calculation&module=profit_calculation&user_token=' +
                        localStorage.token +
                        '&unixtime=' +
                        unixtime +
                        '&mdf=' +
                        shu +
                        '&platform_sku=' +
                        this.platform_skuSearch +
                        '&product_sku=' +
                        this.product_sku +
                        '&type=' +
                        this.type +
                        '&created_start=' +
                        this.star_time +
                        '&created_end=' +
                        this.end_time +
                        '&user_id=' +
                        this.user_id;
                    window.location = location_strs;
                } else {
                    let can = {
                        version: '1.00',
                        action: 'download_profit_calculation',
                        module: 'profit_calculation',
                        user_token: localStorage.token,
                        unixtime: unixtime,
                        user_id: this.user_id
                    };
                    let shu = this.$mdk.mdk(can);
                    let location_strs =
                        '//pretest.ziyehuwai.com/api?version=1.00&action=download_profit_calculation&module=profit_calculation&user_token=' +
                        localStorage.token +
                        '&unixtime=' +
                        unixtime +
                        '&mdf=' +
                        shu +
                        '&user_id=' +
                        this.user_id;
                    window.location = location_strs;
                }
            } else {
                if (
                    !this.platform_skuSearch == '' ||
                    !this.product_sku == '' ||
                    !this.type == '' ||
                    !this.star_time == '' ||
                    !this.end_time == ''
                ) {
                    let can = {
                        version: '1.00',
                        action: 'download_profit_calculation',
                        module: 'profit_calculation',
                        user_token: localStorage.token,
                        unixtime: unixtime,
                        platform_sku: this.platform_skuSearch,
                        product_sku: this.product_sku,
                        type: this.type,
                        created_start: this.star_time,
                        created_end: this.end_time,
                        user_id: this.user_id
                    };
                    let shu = this.$mdk.mdk(can);
                    let location_strs =
                        '//pretest.ziyehuwai.com/api?version=1.00&action=download_profit_calculation&module=profit_calculation&user_token=' +
                        localStorage.token +
                        '&unixtime=' +
                        unixtime +
                        '&mdf=' +
                        shu +
                        '&platform_sku=' +
                        this.platform_skuSearch +
                        '&product_sku=' +
                        this.product_sku +
                        '&type=' +
                        this.type +
                        '&created_start=' +
                        this.star_time +
                        '&created_end=' +
                        this.end_time +
                        '&user_id=' +
                        this.user_id;
                    window.location = location_strs;
                } else {
                    let can = {
                        version: '1.00',
                        action: 'download_profit_calculation',
                        module: 'profit_calculation',
                        user_token: localStorage.token,
                        unixtime: unixtime,
                        user_id: this.user_id
                    };
                    let shu = this.$mdk.mdk(can);
                    let location_strs =
                        '//pretest.ziyehuwai.com/api?version=1.00&action=download_profit_calculation&module=profit_calculation&user_token=' +
                        localStorage.token +
                        '&unixtime=' +
                        unixtime +
                        '&mdf=' +
                        shu +
                        '&user_id=' +
                        this.user_id;
                    window.location = location_strs;
                }
            }
        },
        // 行内编辑
        cellDbClick (row, column, cell, event) {
            //   console.log(column);
            const name = column.property;
            //   console.log(name);
            if (
                !name.includes('exchange_rate') &&
                !name.includes('ocean_freight') &&
                !name.includes('commission_rate') &&
                !name.includes('storage_fee_rate') &&
                !name.includes('advertising_expenses_rate') &&
                !name.includes('after_sales_fees_rate')
            ) {
                // console.log(111);
                return;
            }
            event.target.innerHTML = '';
            let cellInput = document.createElement('input');
            cellInput.value = row[column.property];
            cellInput.setAttribute('type', 'number');
            cellInput.style.width = '60%';
            cellInput.style.marginLeft = '10px';
            cellInput.style.paddingLeft = '5px';
            cell.appendChild(cellInput);
            //   聚焦
            cellInput.focus();
            var rel_this = this;
            //单元格事件
            cellInput.onblur = aaa;
            function aaa () {
                cell.removeChild(cellInput);
                event.target.innerHTML = cellInput.value;
                row[column.property] = cellInput.value;
                // console.log(row[column.property], "失焦");
                // console.log(column.property, "column");
                if (column.property == 'exchange_rate') {
                    //   console.log(row[column.property]);
                    let loginData = {};
                    loginData.version = '1.00';
                    loginData.action = 'edit_profit_calculation_exchange_rate';
                    loginData.user_token = localStorage.token;
                    loginData.module = 'profit_calculation';
                    loginData.id = row.id;
                    loginData.exchange_rate = row[column.property];
                    rel_this.$api.login.login(loginData).then(res => {
                        // console.log(res);
                        if (res.data.status === 1) {
                            rel_this.getserchlist(Number(localStorage.pagenum));
                        } else {
                            rel_this.$message({
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    });
                }
                if (column.property == 'ocean_freight') {
                    //   console.log(row[column.property]);
                    let loginData = {};
                    loginData.version = '1.00';
                    loginData.action = 'edit_profit_calculation_ocean_freight';
                    loginData.user_token = localStorage.token;
                    loginData.module = 'profit_calculation';
                    loginData.id = row.id;
                    loginData.ocean_freight = row[column.property];
                    rel_this.$api.login.login(loginData).then(res => {
                        // console.log(res);
                        if (res.data.status === 1) {
                            rel_this.getserchlist(Number(localStorage.pagenum));
                        } else {
                            rel_this.$message({
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    });
                }
                if (column.property == 'commission_rate') {
                    //   console.log(row[column.property]);
                    let loginData = {};
                    loginData.version = '1.00';
                    loginData.action = 'edit_profit_calculation_commission_rate';
                    loginData.user_token = localStorage.token;
                    loginData.module = 'profit_calculation';
                    loginData.id = row.id;
                    loginData.commission_rate = row[column.property];
                    rel_this.$api.login.login(loginData).then(res => {
                        // console.log(res);
                        if (res.data.status === 1) {
                            rel_this.getserchlist(Number(localStorage.pagenum));
                        } else {
                            rel_this.$message({
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    });
                }
                if (column.property == 'storage_fee_rate') {
                    //   console.log(row[column.property]);
                    let loginData = {};
                    loginData.version = '1.00';
                    loginData.action = 'edit_profit_calculation_storage_fee_rate';
                    loginData.user_token = localStorage.token;
                    loginData.module = 'profit_calculation';
                    loginData.id = row.id;
                    loginData.storage_fee_rate = row[column.property];
                    rel_this.$api.login.login(loginData).then(res => {
                        // console.log(res);
                        if (res.data.status === 1) {
                            rel_this.getserchlist(Number(localStorage.pagenum));
                        } else {
                            rel_this.$message({
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    });
                }
                if (column.property == 'advertising_expenses_rate') {
                    //   console.log(row[column.property]);
                    let loginData = {};
                    loginData.version = '1.00';
                    loginData.action = 'edit_profit_calculation_advertising_expenses_rate';
                    loginData.user_token = localStorage.token;
                    loginData.module = 'profit_calculation';
                    loginData.id = row.id;
                    loginData.advertising_expenses_rate = row[column.property];
                    rel_this.$api.login.login(loginData).then(res => {
                        // console.log(res);
                        if (res.data.status === 1) {
                            rel_this.getserchlist(Number(localStorage.pagenum));
                        } else {
                            rel_this.$message({
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    });
                }
                if (column.property == 'after_sales_fees_rate') {
                    //   console.log(row[column.property]);
                    let loginData = {};
                    loginData.version = '1.00';
                    loginData.action = 'edit_profit_calculation_after_sales_fees_rate';
                    loginData.user_token = localStorage.token;
                    loginData.module = 'profit_calculation';
                    loginData.id = row.id;
                    loginData.after_sales_fees_rate = row[column.property];
                    rel_this.$api.login.login(loginData).then(res => {
                        // console.log(res);
                        if (res.data.status === 1) {
                            rel_this.getserchlist(Number(localStorage.pagenum));
                        } else {
                            rel_this.$message({
                                message: res.data.msg,
                                type: 'error'
                            });
                        }
                    });
                }
            }
        }
    },
    // 保存状态
    beforeRouteLeave (to, form, next) {
        to.meta.keepAlive = true;
        next(0);
    }
};
</script>
<style lang="scss" scoped>
.tit {
    margin-bottom: 10px;
}

.el-pagination {
    display: flex;
    justify-content: flex-end;
    margin: 10px 20px 0 0;
}

.add {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 20px;

    .inp {
        // width: 60%;
        // height:110px;
        // padding: 5px;
        // box-shadow: 4px 4px 10px #000;

        .inp-top {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            // margin-bottom: 10px;
            width: 90%;

            .el-input {
                width: 19%;
            }

            .el-select {
                width: 19%;
            }

            .el-date-editor {
                width: 30%;
            }
        }

        .inp-bottom {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        .serch {
            margin-left: 20px;
        }
    }

    .ban {
        width: 290px;
        height: 40px;
        line-height: 40px;
        margin-left: 10px;
    }

    // .el-input {
    //     width: 200px;
    // }

    .status {
        width: 200px;
    }
}

// .el-dialog__body .el-input {
//     width: 100%;
// }

.el-form-item__label {
    text-align: justify !important;
    text-align-last: justify !important;
}

.divright {
    display: flex;
    flex-wrap: nowrap;
    height: 40px;
    justify-content: space-between;
}

.el-form {
    .el-input {
        width: 34%;
    }

    .elselect {
        width: 34%;
    }
}

.downloads-button {
    width: 100px;
    height: 40px;
    // overflow: hidden;
    position: relative;
    width: 68px;

    input {
        width: 100px;
        height: 40px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
    }
}

@media (max-width: 750px) {
    .phone {
        width: 100%;

        .phone-inp {
            .phone-inp-top {
                .el-input {
                    width: 43%;
                }

                .el-select {
                    width: 43%;
                }

                .el-date-editor {
                    width: 43%;
                }
            }
        }

        .phone-inp-bottom {
            margin: 0 0 10px;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-end;
        }
    }

    #phone-form {
        .el-form-item {
            .el-input {
                width: 90%;
            }

            .el-select {
                width: 90%;
            }
        }
    }

    .downloads-button {
        width: 76px;
        height: 28px;
        overflow: hidden;
    }
}
</style>

export default{

    authentication(val){
       var  permission = localStorage.permission 
        if(permission.indexOf(val) >-1){
            // 有权限
            return false
        }else{
            // 没权限
            return true
        }
    }

}
   



<template>
<div class="box">
    <el-breadcrumb separator-class="el-icon-arrow-right">
  <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
  <el-breadcrumb-item>活动管理</el-breadcrumb-item>
</el-breadcrumb>
</div>  
</template>

<script>
export default {

}
</script>

<style>

</style>
import md5 from "md5"; //在使用的页面引入加密插件
export default{
    mdk(obj) {
        let m_k = "jhjkhJAIH4GRKJSAD455";
        var key_list = [];
        for (var i in obj) {
          if (i != "module" && i != "action") {
            key_list.push(i);
          }
        }
        key_list.sort();
        let mdf_str = "";
        for (var b in key_list) {
          mdf_str += obj[key_list[b]];
        }
        mdf_str += m_k;
        var res_str = md5(mdf_str);
        return res_str;
      },
}
import http from "../modules/http";
import Vue from 'vue'

export default {

    ImportXlsx(v, data, mdfs) {
        return new Promise((resolve, reject) => {
            let query = {
                file: v.target? v.target.files[0]:v.file,
                    mdf: mdfs
            }
            query = Object.assign(query,data)
            http({
                url: `//pretest.ziyehuwai.com/api`, //上传文件接口
                method: "post",
                headers: {
                    //请求头，根据自身需求加或者不加
                    "Content-Type": "multipart/form-data",
                },
                data: query,
                // data: {
                //     file: v.target? v.target.files[0]:v.file,
                //     mdf: mdfs,
                //     action: data.action,
                //     user_token: data.user_token,
                //     version: data.version,
                //     module: data.module,
                //     unixtime: data.unixtime,
                // },
            }).then((res) => {
                if (res.data.status == 1) {
                    // setTimeout(() => {
                    //          location.reload() 
                    //     }, 1000);
                    Vue.prototype.$message({
                        message: res.data.msg,
                        type: "success",
                    });
                    if(res.data.result.product_sku) {
                        resolve(res.data.result.product_sku)
                    }
                    if(res.data.result && res.data.result.length!==0) {
                        resolve(res.data.result)
                    }
                } else {
                        Vue.prototype.$message({
                            message: res.data.msg,
                            type: 'error',
                            duration: 5000 ,// 持续时间为 5000 毫秒（5 秒）
                            offset:300
                        });
                    reject()
                }
            
            })
        })
        
    },
    uploadImage(img) {
        return new Promise((resolve, reject) => {
            http({
                url: `//pretest.ziyehuwai.com/api/upload_img`, //上传文件接口
                method: "post",
                headers: {
                    //请求头，根据自身需求加或者不加
                    "Content-Type": "multipart/form-data",
                },
                data: {
                    data: img
                },
            }).then((res) => {
                if (res.data.status == 1) {
                    Vue.prototype.$message({
                        message: res.data.msg,
                        type: "success",
                    });
                   resolve(res.data.url)
                } else {
                        Vue.prototype.$message({
                            message: res.data.msg,
                        });
                    reject()
                }
            
            })
        })
    },
    uploadJson(data) {
        return new Promise((resolve, reject) => {
            http({
                url: `//pretest.ziyehuwai.com/api`, //上传文件接口
                method: "post",
                responseType: 'blob',
                headers: {
                    //请求头，根据自身需求加或者不加
                    "Content-Type": "multipart/form-data",
                },
                data: data,
            }).then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'filename.xlsx'); // 设定下载文件名，如 filename.xlsx
                document.body.appendChild(link);
                link.click();
                link.remove(); // 完成后移除元素
            })
        })
    }
}
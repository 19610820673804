import Vue from 'vue'
export default 
{
    

    login(data) {
        var unixtime = parseInt(new Date().getTime() / 1000 + '');
        data['unixtime']=unixtime;
       // mdf='';
        var mdf=Vue.prototype.$common.set_req(data);
        
        data['mdf']=mdf;
        
        var params = new URLSearchParams();
        for(var i in data){
            params.append(i, data[i]);
        }
       //  console.log(data);
        var url  = '/api'
        return Vue.prototype.$http.post(url , params);
        
    }
}
export default{
    dates (originVal) {
        const dt = new Date(originVal)
        const y = dt.getFullYear()
        const m = (dt.getMonth() + 1 + '').padStart(2, '0')
        const d = (dt.getDate() + '').padStart(2, '0')
        //时间格式年月日、时分秒
        return `${y}-${m}-${d}`
      },
    // 升级版时间转换
    formatDate(format,timestamp) {
        if(timestamp <= 0) {
            format = '暂无数据'
        } else {
            // 将时间戳转换为日期对象
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            // 替换需要的时间格式
            format = format.replace('yyyy', year);
            format = format.replace('MM', month < 10 ? '0' + month : month);
            format = format.replace('dd', day < 10 ? '0' + day : day);
            format = format.replace('HH', hours < 10 ? '0' + hours : hours);
            format = format.replace('mm', minutes < 10 ? '0' + minutes : minutes);
            format = format.replace('ss', seconds < 10 ? '0' + seconds : seconds);
        }
        return format;
    }
}
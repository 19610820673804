import { time } from "echarts";

const state = {
	params: [
        { sku_long: '',
             sku_high: '', 
             sku_weight: '', 
             sku_wide: '', 
             cost_price: '' 
            }
        ],
	selling_price: '',
	code1: '',
	searchQuery: {
		//利润测算列表美国
		platform_skuSearch: '',
		product_sku: '',
		type: '',
		product_name: ''
	},
	profitTime: [], //利润测算列表美国
	skuMappingListSearchQuery: {
		//平台sku映射关系搜索
		mark: '',
		platform_sku: '',
		product_sku: '',
		fnsku: '',
		country: '',
		version_code: '',
		jibie: []
	},
    profitEuropeSearch:{
        //利润测算列表欧洲
        platform_skuSearch: '',
        product_sku: '',
        product_name: '',				
        type: '',
    },
    profitEuropeTime:null, //利润测算列表欧洲
    profitCanadaSearch:{
        //利润测算列表加拿大
        platform_skuSearch: '', 
        product_sku: '', 
        type: '',
        product_name:"",
    },
};

const mutations = {
	setParams(state, newParams) {
		state.params = newParams;
	},
	setParams1(state, newSellingPrice) {
		state.selling_price = newSellingPrice;
	},
	setParams2(state, newCode1) {
		state.code1 = newCode1;
	},
	setSearchQuery(state, newSearchQuery) {
		state.searchQuery = newSearchQuery;
	},
    /**
 * 设置欧洲搜索利润
 * 
 * @param {Object} state - 包含状态数据的对象
 * @param {number} newProfitEuropeSearch - 新的欧洲搜索利润值
 */
	setProfitEuropeSearch(state, newProfitEuropeSearch) {
		state.profitEuropeSearch = newProfitEuropeSearch;
	},
	setProfitEuropeTime(state, newProfitEuropeTime) {
		state.profitEuropeTime = newProfitEuropeTime;
	},
    setProfitCanadaSearch(state, newProfitCanadaSearch){
        state.profitCanadaSearch = newProfitCanadaSearch;
    },
	setProfitMeasurementList(state, newProfitMeasurementList) {
		state.profitTime = newProfitMeasurementList;
	},
	setMappingList(state, newSkuMappingListSearchQuery) {
		state.skuMappingListSearchQuery = newSkuMappingListSearchQuery;
	}
};
const getters = {
	params: state => state.params,
	selling_price: state => state.selling_price,
	code1: state => state.code1,
	searchQuery: state => state.searchQuery,
	profitTime: state => state.profitTime,
	skuMappingListSearchQuery: state => state.skuMappingListSearchQuery,
	profitEuropeSearch: state => state.profitEuropeSearch,
	profitEuropeTime: state => state.profitEuropeTime,
	profitCanadaSearch: state => state.profitCanadaSearch
};
const actions = {};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
};

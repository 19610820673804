<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>
<script>
// import Homeview from './views/index.vue'
export default {
	data() {
		return {};
	},
	created() {
		// 防止刷新白屏
		if (localStorage.menu) {
			let router = JSON.parse(localStorage.menu);
			this.$addRouters(router);
		}
		this._isMobile();
	},
	beforeMount() {},
	methods: {
		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			);
			if (flag) {
				localStorage.setItem('isMobile', true);
			} else {
				localStorage.setItem('isMobile', false);
			}
		}
	},
	// 保存状态
	beforeRouteLeave(to, form, next) {
		to.meta.keepAlive = true;
		next(0);
	}
};
</script>
<style lang="scss">
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.el-dialog {
	box-shadow: none !important;
}

// ::v-deep .el-table__body tr.current-row>td {
// 	color: #fff;
// 	background: #409EFF!important;
// }
/* 普通IE浏览器 样式清除 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
}
/* 火狐浏览器样式清除 */
input[type='number'] {
	-moz-appearance: textfield;
}
.el-input__inner {
	line-height: 1px !important;
}
</style>

import login from '@/api/login'
const state = {
    applyNum: 0,
    loading: false
}

// mutations 里面装着改变数据的方法集合，处理数据逻辑的方法全部放在 mutations 里，使数据和视图分离。
const mutations = {
    setApplyNum(state,view) {
        state.applyNum = view
    }
}

// action 类似于 mutation ，不同在于：
// action 提交的是 mutation，通过 mutation 来改变 state ，而不是直接变更状态。
// action 可以包含任意异步操作。
const actions = {
    getApplyPurchaseNum(fun) {
        if(fun.state.loading == true) return
        fun.state.loading = true
        var loginData = {};
        loginData.version = '1.00';
        loginData.action = 'get_need_order_request_list';
        loginData.user_token = localStorage.token;
        loginData.module = 'operation_chain';
        loginData.page = 1;
        loginData.num = 10;
        loginData.user_id = localStorage.user_id;
        loginData.status = 0;
        login.login(loginData).then(res => {
            fun.state.loading = false
            if (res.data.status === 1) {
                fun.commit('setApplyNum',res.data.result.total)
            }
        }).catch(err => {
            fun.state.loading = false
        })
    },
    setIntervalGetNum(fun) {
        clearInterval(Window.applyTimer)
        Window.applyTimer = setInterval(() => {
            fun.dispatch('getApplyPurchaseNum')
        }, 60000 * 3);
    },
    clearIntervalGetNum(fun) {
        clearInterval(Window.applyTimer)
    }
}

export default {
    state,
    mutations,
    actions
}
<template>
	<div>
		<el-menu ref="menu" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" > 
			<template v-for="item in routes">
				<!-- <el-menu-item :index="item.name_en" v-if="!item.list" :key="item.name_en">{{ item.name }}</el-menu-item> -->
				<el-submenu popper-class="heightTe" :index="item.name_en" v-if="item.list" :key="item.name_en">
					<el-menu-item v-if="!item.list[0]" disabled>暂无选项</el-menu-item>
					<template slot="title">{{ item.name }}</template>
					<template v-for="items in item.list">
						<el-menu-item :index="items.name_en" :key="items.name_en">{{ items.name }}</el-menu-item>
						<!-- <el-submenu v-if="items.list" :index="items.name_en" :key="items.name_en">
              <template slot="title">{{ items.name }}</template>
              <el-menu-item :index="itemss.name_en"  v-for="itemss in items.list" :key="itemss.name_en">{{ itemss.name}}</el-menu-item>
            </el-submenu> -->
					</template>
				</el-submenu>
			</template>
		</el-menu>
	</div>
</template>

<script>
export default {
	components: {},
	props: ['name'],
	data() {
		return {
			menu: [],
			tables: [],
			activeIndex: 'access',
			path: '',
			menus: [],
			routes: [
				// {
				//   name: "库存",
				//   name_en: "kucun",
				//   list: [
				//     {
				//       name: "库存管理",
				//       name_en: "kc_admin",
				//       list:[
				//         {
				//           name:'商品',
				//           name_en:'sp'
				//         }
				//       ]
				//     },
				//   ],
				// },
				// {
				//   name: "成员",
				//   name_en: "cy",
				//   list: [
				//     {
				//       name: "成员管理",
				//       name_en: "cy_admin",
				//     },
				//   ],
				// },
				// {
				//   name:'账号',
				//   name_en:'access'
				// }
			]
		};
	},
	created() {
		this.init();
	},
	watch: {
		$route: {
			handler(val, oldVal) {
				if (oldVal.path.includes(val.path) && val.matched.length != oldVal.matched.length) {
					this.$router.push(
						this.$route.fullPath + '/' + this.routes[0].name_en + '/' + this.routes[0].list[0].name_en
					);
					this.$refs.menu.activeIndex = this.routes[0].list[0]?.name_en;
				} else {
					// created中的方法增加修改为watch实时监测，防止之前已经打开过该页面，切换后重新回到当前页面没有执行路由添加操作
					this.init();
				}
			}
		}
	},
	methods: {
		init() {
			this.menus = JSON.parse(localStorage.menu);
			this.routes = this.menus;
			this.path = this.$router.history.current.path.split('/')[2];
			// console.log('beforeRoute', this.routes);
			// console.log('path', this.path);
			this.routes.forEach(item => {
				if (item.list) {
					for (let i = 0; i < item.list.length; i++) {
						if (item.list[i].name_en == this.path) {
							this.routes = item.list[i].list;
							break;
						}
					}
				}
			});
			if (this.routes.length == 0) {
				// 解决有tables页面切换到没有tables页面 匹配不到route导致报错
				return;
			}
			if (this.$router.history.current.matched.length == 3) {
				this.activeIndex = this.routes[0].list[0]?.name_en;
			} else {
				let routePath = this.$router.history.current.path.split('/');
				this.activeIndex = routePath[routePath.length - 1];
			}
			if (this.$route.matched.length == 3 && this.$route.matched.length <= 3) {
				this.$router.push(
					this.$route.fullPath + '/' + this.routes[0].name_en + '/' + this.routes[0].list[0].name_en
				);
			}
		},
		handleSelect(key, keyPath) {
			let routePath = this.$route.matched[2].path + '/' + keyPath[0] + '/' + keyPath[1];
			// console.log(routePath);
			this.$router.push(routePath);
		}
	}
};
</script>

<style lang="scss" scoped>
.top {
	width: 70%;
	height: 40px;
	// background-color: yellow;

	ul {
		width: 50%;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;

		li {
			width: 100px;
			height: 40px;
			// background: red;
			// padding: 0;
			// border: 1px solid gray;
			background-color: #409eff;
			color: #fff;
			cursor: pointer;
			border-radius: 6px;
			list-style: none;

			span {
				display: block;
				line-height: 40px;
				text-align: center;
				font-size: 16px;
				font-weight: 400;
			}

			.none {
				width: 100px;
				// height: 200px;
				background: #fff;
				display: none;

				span {
					font-size: 14px;
					// border: 1px solid gray;
					// border-radius: 10px;
					color: #606266;
				}

				span:hover {
					background: #ecf5ff;
					color: #409eff;
				}
			}
		}
	}

	li:hover .none {
		display: block;
	}
}

.block {
	border: 1px solid red;
}
// .el-menu--popup-bottom-start{
//     height: 400px;
//     overflow-y: auto;
// }
  /*菜单下拉框样式开始*/

  .el-menu-item:hover{
    background-color: #EAF5FF!important;
    color: #53B1FD!important;
  }
  /*菜单下拉框样式结束*/
  .el-menu-item.is-active {
      background-color: #3370ff !important;//选中背景色
      color: #fff !important;//选中颜色
    //   span {
    //     color: #fff !important;
    //   }
    }

</style>
<style>
.heightTe{
    height: 400px;
    overflow-y: auto;
}
</style>